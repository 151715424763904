<template>
	<v-row>
		<v-col cols="12">
			<v-card color="primary" dark>
				<v-card-title class="px-4 pb-0">
          <v-col cols="12" class="pa-0">
            <v-row class="px-1 py-0">
              <v-col class="px-2">
                <v-select
                    label="Período"
                    filled
                    dense
                    :items="periodos"
                    item-text="data"
                    item-value="data"
                    :disabled="carregando || carregandoTotais ||carregandoTotais2"
                    v-model="busca.periodo.data"
                    hide-details
                    @change="listarVendas(), listarPeriodos()"
                ></v-select>
              </v-col>
              <v-col class="px-2">
                <v-select
                    label="Filial"
                    filled
                    dense
                    :items="filiaisFiltro"
                    item-text="filial"
                    item-value="idfilial"
                    :disabled="carregando || carregandoTotais || carregandoTotais2"
                    v-model="busca.idempresa"
                    hide-details
                    clearable
                    @change="listarVendas()"
                ></v-select>
              </v-col>
              <v-col class="px-2" v-show="!busca.idempresa">
                <v-select
                    label="Regional"
                    filled
                    dense
                    :items="regionais"
                    item-text="regional"
                    item-value="idregional"
                    :disabled="carregando || carregandoTotais || carregandoTotais2"
                    v-model="busca.idregional"
                    hide-details
                    clearable
                    @change="listarVendas()"
                ></v-select>
              </v-col>
              <v-col class="px-2" v-show="!busca.idempresa">
                <v-select
                    label="Seção"
                    filled
                    dense
                    :items="secoes"
                    item-text="descr"
                    item-value="idsecao"
                    :disabled="carregando || carregandoTotais || carregandoTotais2"
                    v-model="busca.idsecao"
                    hide-details
                    clearable
                    @change="listarVendas()"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-3 pt-4">
            <v-row v-if="dataUltAtualizacao">
              <v-spacer/><span class="text-caption">Atualizado em: {{dataUltAtualizacao}}</span>
            </v-row>
            <v-row v-else>
              <v-spacer/>
              <v-progress-circular
                class="mt-1 mr-1"
                indeterminate
                color="white"
                :size="12"
            ></v-progress-circular>
              <span class="text-caption font-italic">Carregando...</span>
            </v-row>
          </v-col>
				</v-card-title>
        <v-card-text class="pt-0">
          <v-skeleton-loader light v-if="carregando" type="table-tbody" />
          <v-simple-table light v-else-if="vendasLojas[0] && !carregando" fixed-header height="50vh">
            <thead ref="thead">
              <tr class="border-line-head">
                <th class="fixed-column-header white--text text-center" nowrap>
                  <v-row class="pb-0" no-gutters>
                    <v-col cols="3" class="pa-0" style="display: flex; justify-content: start; align-items: start;">
                      <div>
                        <v-tooltip bottom color="primary" class="text-no-wrap">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="carregandoTotais" v-bind="attrs" v-on="on" @click="dialog = true" elevation="0" class="primary mt-n1 ml-n4 pa-0" x-small style="border-radius: 0px;" height="39px" dark><v-icon class="mt-1">mdi-arrow-expand-all</v-icon></v-btn>
                          </template>
                          <span>Visualizar em tela cheia</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="9" class="pa-0" style="display: flex; justify-content: center; align-items: center;">
                      <div class="mr-11">
                        DATA
                      </div>
                    </v-col>
                  </v-row>
                </th>
                <th class="text-center white--text" nowrap v-if="validarNumero('1')">IVAIPORÃ</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('2')">PITANGA</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('3')">SJ. IVAÍ</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('4')">CASTELO</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('5')">CANDIDO</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('7')">APUCARANA</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('8')">J. ALEGRE</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('9')">M. RIBAS</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('10')">FAXINAL</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('11')">C. MOURÃO</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('14')">CIANORTE</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('15')">PARANAVAÍ</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('16')">TELÊMACO</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('17')">RONCADOR</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('18')">GUARAPUAVA</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('19')">PALMITAL</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('20')">IBIPORÃ</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('21')">TURVO</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('23')">S. MATEUS</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('24')">SJ. TRIUNFO</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('26')">PONTAL</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('27')">MANDAGUARI</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('28')">ASTORGA</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('30')">CAMBÉ</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('31')">RESERVA</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('35')">ROLÂNDIA</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('36')">MARINGÁ</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('38')">LARANJEIRAS</th>
                <th class="text-center white--text" nowrap v-if="validarNumero('39')">CD ECOMMERCE</th>
                <th class="text-center white--text barra-total-left" nowrap>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(u, i) in vendasLojas" :key="i" class="border-line">
                <td class="px-2 text-center fixed-column" nowrap> {{ u.data | formataData}} </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(1)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja1<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja1 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(2)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja2<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja2 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(3)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja3<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja3 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(4)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja4<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja4 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(5)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja5<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja5 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(7)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja7<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja7 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(8)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja8<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja8 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(9)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja9<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja9 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(10)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja10<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja10 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(11)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja11<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja11 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(14)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja14<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja14 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(15)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja15<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja15 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(16)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja16<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja16 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(17)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja17<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja17 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(18)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja18<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja18 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(19)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja19<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja19 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(20)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja20<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja20 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(21)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja21<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja21 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(23)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja23<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja23 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(24)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja24<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja24 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(26)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja26<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja26 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(27)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja27<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja27 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(28)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja28<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja28 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(30)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja30<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja30 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(31)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja31<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja31 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(35)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja35<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja35 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(36)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja36<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja36 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(38)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja38<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja38 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(39)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja39<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja39 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left column-totais" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.total_venda_bruta_dia <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.total_venda_bruta_dia | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="vendasLojas[0] && vendasLojas[0]">
              <tr v-if="totalizadores.vendas.totaloja[0]" class="barra-total-top">
                <td colspan="100%" style="height: 3px !important"></td>
              </tr>
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Líquida</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendaliquida['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendaliquida['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.variacoes[0].totalvendaliquida<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.variacoes[0].totalvendaliquida | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>                     <!--  Total Venda Líquida -->
              <tr v-if="totalizadores.vendas.totaloja[0]" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Bruta</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.vendas.totaloja[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.vendas.totaloja[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.vendas.totalvendaLiquida <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.vendas.totalvendaLiquida | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                                    <!--  Total Venda Bruta -->
              <tr v-if="totalizadores.vendas.perclojas[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Geral</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.vendas.perclojas[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.vendas.perclojas[0].lojas['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap :style="totalizadores.vendas.perclojas[0].totalparticipacaogeral<0?'color:#F22233;':''">
                  {{ totalizadores.vendas.perclojas[0].totalparticipacaogeral.toFixed(2) }}%
                </td>
              </tr>              <!--  % Partic. Geral -->
              <tr v-if="totalizadores.vendas.percpartregionais[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Regional</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.vendas.percpartregionais[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.vendas.percpartregionais[0].lojas['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap  :style="totalizadores.vendas.percpartregionais[0].totalpartregional<0?'color:#F22233;':''">
                  {{ totalizadores.vendas.percpartregionais[0].totalpartregional.toFixed(2) }}%
                </td>
              </tr>      <!--  % Partic. Região -->
              <tr v-if="totalizadores.metasMes[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                          <!-- ......................  -->
              <tr v-if="totalizadores.metasMes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Meta de Venda - Mês</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasMes[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasMes[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.metasMes[0].totalmeta<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.metasMes[0].totalmeta | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>                      <!--  Meta de venda - Mes -->
              <tr v-if="totalizadores.metasDia[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Meta de Venda Acumulada</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasDia[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasDia[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.metasDia[0].totalmeta<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.metasDia[0].totalmetadia | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>                      <!--  Meta de venda Acumulada -->
              <tr v-if="totalizadores.metasVariacao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">% Variação de Meta</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarMeta(totalizadores.metasVariacao[0].lojas['loja' + n])">
                    {{ totalizadores.metasVariacao[0].lojas['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :class="coresVarMeta(totalizadores.metasVariacao[0].TotalMetaVariacao)">
                    {{ totalizadores.metasVariacao[0].TotalMetaVariacao.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                 <!--  % Variação de Meta -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compvariacao">Venda Liq. Ano Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendabrutaanoanterior['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendabrutaanoanterior['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.variacoes[0].totalvendabrutaanoanterior<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.variacoes[0].totalvendabrutaanoanterior | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                     <!--  Venda Liq. Ano Ant. -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Nominal</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadores.variacoes[0].variacaonominalperc['loja' + n])">
                    {{ totalizadores.variacoes[0].variacaonominalperc['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :class="coresVarariacoes(totalizadores.variacoes[0].totalpercvariacaonominal)">
                    {{ totalizadores.variacoes[0].totalpercvariacaonominal.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                     <!--  % Var. Nominal -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Mês Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendabrutamesanterior['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendabrutamesanterior['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.variacoes[0].totalvendabrutamesanterior <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{totalizadores.variacoes[0].totalvendabrutamesanterior | formataDinheiroTabela}} </span>
                  </div>
                </td>
              </tr>                     <!--  Venda Liq. Mês Ant. -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Mês Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadores.variacoes[0].vendabrutavariacaomes['loja' + n])">
                    {{ totalizadores.variacoes[0].vendabrutavariacaomes['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :color="coresVarariacoes(totalizadores.variacoes[0].totalpercvariacao)">
                    {{ totalizadores.variacoes[0].totalpercvariacao.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                     <!--  % Var. Mês Ant. -->
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                         <!-- ......................  -->
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compdevolucao">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Vendas de Obsoletos (R$)
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="red"
                          class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Apoio a decisão</span>
                  </v-tooltip>
                </td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.vendasObsoletos[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.vendasObsoletos[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.vendasObsoletos[0].total_venda_obsoleto | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                     <!--  Vendas de Obsoletos (R$) -->
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Margem de Obsoletos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :color="coresMargem(totalizadores.vendasObsoletos[0].margens['loja' + n])">
                    {{ totalizadores.vendasObsoletos[0].margens['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :color="coresMargem(totalizadores.vendasObsoletos[0].margem_venda_obsoleto)">
                    {{ totalizadores.vendasObsoletos[0].margem_venda_obsoleto.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                  <!--  Margem Obsoletos (%) -->
              <tr v-if="totalizadores.estoqueObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque de Obsoletos</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.estoqueObsoletos[0].lojas['loja' + n] | formataDinheiro }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.estoqueObsoletos[0].total_estoque | formataDinheiro }}
                </td>
              </tr>               <!--  Estoque de Obsoletos - Em desenvolvimento ...-->
              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                         <!-- ......................  -->
              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compdevolucao">Total Devoluções (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.devolucao[0].devolucao['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.devolucao[0].devolucao['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.devolucao[0].totaldevolucao | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                     <!--  Total Devoluções (R$) -->
              <tr v-if="totalizadores.perDevolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Total Devoluções (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.perDevolucao[0].devolucao['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.perDevolucao[0].devolucao['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <!--                  <v-chip small label :color="totalizadores.desconto[0].totaldescontoconcedido<0?'error':''">-->
                  {{ totalizadores.perDevolucao[0].totaldevolucao.toFixed(2) }}%
                  <!--                  </v-chip>-->
                </td>
              </tr>                  <!--  Total Devoluções (%) -->
              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                         <!-- ......................  -->
              <tr v-if="totalizadores.metasDesconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Metas de Descontos (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasDesconto[0].metadescontoreal['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasDesconto[0].metadescontoreal['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.metasDesconto[0].metatotalreal <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.metasDesconto[0].metatotalreal | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Metas de Descontos (R$) -->
              <tr v-if="totalizadores.metasPerDesconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Metas de Descontos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.metasPerDesconto[0].metadesconto['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.metasPerDesconto[0].metadesconto['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.metasPerDesconto[0].metatotal.toFixed(2) }}%
                </td>
              </tr>              <!--  Metas de Descontos (%)  -->
              <tr v-if="totalizadores.desconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.desconto[0].valordesconto['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.desconto[0].valordesconto['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.totalvendabrutageral <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.desconto[0].totaldesconto | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                      <!--  Total Desconto -->
              <tr v-if="totalizadores.desconto[0] && totalizadores.metasPerDesconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresPerDesconto(['loja' + n])">
                    {{ totalizadores.desconto[0].descontoconcedido['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :class="totalizadores.desconto[0].totaldescontoconcedido>totalizadores.metasPerDesconto[0].metatotal?'red white--text':'blue darken-2 white--text'">
                    {{ totalizadores.desconto[0].totaldescontoconcedido.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>  <!--  % Desconto -->
              <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                            <!-- ......................  -->
              <tr v-if="totalizadores.margemMeta[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.margemMeta[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.margemMeta[0].lojas['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap :style="totalizadores.margemMeta[0].totalmargemmeta<0?'color:#F22233;':''">
                  {{ totalizadores.margemMeta[0].totalmargemmeta.toFixed(2) }}%
                </td>
              </tr>                    <!--  Margem Meta - Em desenvolvimento ...-->
              <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Realizada Acum.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].margem['loja' + n])">
                    {{ totalizadores.margem[0].margem['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].margem.loja38)">
                    {{ totalizadores.margem[0].totalmargematual.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                        <!--  Margem Realizada Acum. -->
              <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Ano Anterior</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].margemanopassado['loja' + n])">
                    {{ totalizadores.margem[0].margemanopassado['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].totalmargemlastyear)">
                    {{ totalizadores.margem[0].totalmargemlastyear.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                        <!--  Margem Ano Anterior -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Entrada Ajuste de Estoque</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].lojasent['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].lojasent['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.entradasSaidasAvulsa[0].totalentrada<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.entradasSaidasAvulsa[0].totalentrada | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Entrada Avulsa -->
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saída Ajuste de Estoque</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].lojassaid['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].lojassaid['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.entradasSaidasAvulsa[0].totalsaida<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.entradasSaidasAvulsa[0].totalsaida | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Saida Avulsa -->
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saldo de Ajustes</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].saldo['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].saldo['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.entradasSaidasAvulsa[0].totalsaldo<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.entradasSaidasAvulsa[0].totalsaldo | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Saldo -->
              <tr v-if="totalizadores.estoqueAnoAnt[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                           <!-- ......................  -->
              <tr v-if="totalizadores.estoqueAnoAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Estoque Ano Anterior</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoqueAnoAnt[0].estoqued1['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoqueAnoAnt[0].estoqued1['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.estoqueAnoAnt[0].totalestoqued1<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoqueAnoAnt[0].totalestoqued1 | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Estoque Ano Anterior -->
              <tr v-if="totalizadores.diasEstoqueFuturo[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Futuro</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.diasEstoqueFuturo[0].lojas['loja' + n] }} dias
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.diasEstoqueFuturo[0].totaldiasestoquefuturo }} dias
                </td>
              </tr>             <!--  Dias de Estoque - Futuro - Em desenvolvimento ...-->
              <tr v-if="totalizadores.diasEstoqueReal[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Real</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.diasEstoqueReal[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.diasEstoqueReal[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.diasEstoqueReal[0].totaldiasestoquereal<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.diasEstoqueReal[0].totaldiasestoquereal | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>               <!--  Dias de Estoque - Real - Em desenvolvimento ...-->
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Inicial</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].estoqued2['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].estoqued2['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.estoque[0].totalestoqued2<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalestoqued2 | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>                       <!--  Estoque Inicial -->
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Movimentação</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].Movimentacoes['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].Movimentacoes['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.estoque[0].totalMovimentacoes<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalMovimentacoes | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                       <!--  Movimentação -->
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Final</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].estoqued1['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].estoqued1['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.estoque[0].totalestoqued1<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalestoqued1 | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                       <!--  Estoque Final -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                           <!-- ......................  -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Saldo Pedidos de Compra
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="primary"
                          class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Custo Última Compra</span>
                  </v-tooltip>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(1, 'Ivaiporã')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                          <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja1 | formataDinheiroTabela}} </span>
                        </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(2, 'Pitanga')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja2 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(3, 'São João do Ivaí')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja3 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(4, 'Castelo')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja4 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(5, 'Candido')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja5 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(7, 'Apucarana')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja7 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(8, 'Jardim Alegre')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja8 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(9, 'Manoel Ribas')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja9 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(10, 'Faxinal')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja10 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(11, 'Campo Mourão')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja11 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(14, 'Cianorte')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja14 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(15, 'Paranavaí')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja15 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(16, 'Telêmaco')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja16 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(17, 'Roncador')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja17 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(18, 'Guarapuava')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja18 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(19, 'Palmital')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja19 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(20, 'Ibiporã')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja20 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(21, 'Turvo')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja21 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(23, 'São Mateus')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja23 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(24, 'São João do Triunfo')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja24 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(26, 'Pontal do Paraná')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja26 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(27, 'Mandaguari')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja27 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(28, 'Astorga')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja28 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(30, 'Cambé')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja30 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(31, 'Reserva')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja31 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(35, 'Rolândia')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja35 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(36, 'Maringá')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja36 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(38, 'Laranjeiras')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja38 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(39, 'E-commerce - CD')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja39 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>R$</span><span>{{ totalizadores.pedidos[0].totalpedidos | formataDinheiroTabela}} </span>
                  </div>
                </td>
              </tr>                       <!--  Saldo Pedidos -->
              <tr v-if="totalizadores.itensAtivos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.itensAtivos[0].lojas['loja' + n] }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.itensAtivos[0].totalitensativos }}
                </td>
              </tr>                   <!--  Total de Sku's em Estoque -->
              <tr v-if="totalizadores.itensEncalhados[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.itensEncalhados[0].lojas['loja' + n] }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.itensEncalhados[0].totalitensencalhados }}
                </td>
              </tr>               <!--  Sku's Encalhados - Em desenvolvimento ...-->
              <tr v-if="totalizadores.rupturaA[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                          <!-- ......................  -->
              <tr v-if="totalizadores.rupturaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva A</td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(1, 'RA', 'Ivaiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja1 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(2, 'RA', 'Pitanga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja2 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(3, 'RA', 'São João do Ivaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja3 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(4, 'RA', 'Castelo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja4 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(5, 'RA', 'Candido')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja5 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(7, 'RA', 'Apucarana')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja7 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(8, 'RA', 'Jardim Alegre')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja8 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(9, 'RA', 'Manoel Ribas')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja9 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(10, 'RA', 'Faxinal')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja10 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(11, 'RA', 'Campo Mourão')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja11 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(14, 'RA', 'Cianorte')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja14 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(15, 'RA', 'Paranavaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja15 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(16, 'RA', 'Telêmaco')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja16 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(17, 'RA', 'Roncador')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja17 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(18, 'RA', 'Guarapuava')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja18 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(19, 'RA', 'Palmital')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja19 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(20, 'RA', 'Ibiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja20 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(21, 'RA', 'Turvo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja21 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(23, 'RA', 'São Mateus')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja23 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(24, 'RA', 'São João do Triunfo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja24 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(26, 'RA', 'Pontal do Paraná')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja26 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(27, 'RA', 'Mandaguari')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja27 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(28, 'RA', 'Astorga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja28 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(30, 'RA', 'Cambé')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja30 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(31, 'RA', 'Reserva')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja31 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(35, 'RA', 'Rolândia')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja35 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(36, 'RA', 'Maringá')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja36 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(38, 'RA', 'Laranjeiras')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja38 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(39, 'RA', 'E-commerce - CD')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja39 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center barra-total-left" nowrap >
                  {{ totalizadores.rupturaA[0].totalrupturaa }}
                </td>
              </tr>                     <!--  Sku's em Ruptura da Curva A -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A</td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(1, 'CA', 'Ivaiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja1.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(2, 'CA', 'Pitanga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja2.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(3, 'CA', 'São João do Ivaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja3.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(4, 'CA', 'Castelo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja4.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(5, 'CA', 'Candido')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja5.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(7, 'CA', 'Apucarana')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja7.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(8, 'CA', 'Jardim Alegre')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja8.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(9, 'CA', 'Manoel Ribas')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja9.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(10, 'CA', 'Faxinal')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja10.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(11, 'CA', 'Campo Mourão')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja11.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(14, 'CA', 'Cianorte')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja14.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(15, 'CA', 'Paranavaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja15.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(16, 'CA', 'Telêmaco')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja16.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(17, 'CA', 'Roncador')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja17.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(18, 'CA', 'Guarapuava')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja18.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(19, 'CA', 'Palmital')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja19.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(20, 'CA', 'Ibiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja20.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(21, 'CA', 'Turvo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja21.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(23, 'CA', 'São Mateus')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja23.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(24, 'CA', 'São João do Triunfo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja24.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(26, 'CA', 'Pontal do Paraná')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja26.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(27, 'CA', 'Mandaguari')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja27.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(28, 'CA', 'Astorga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja28.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(30, 'CA', 'Cambé')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja30.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(31, 'CA', 'Reserva')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja31.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(35, 'CA', 'Rolândia')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja35.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(36, 'CA', 'Maringá')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja36.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(38, 'CA', 'Laranjeiras')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja38.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(39, 'CA', 'E-commerce - CD')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja39.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center barra-total-left" nowrap >
                  {{ totalizadores.curva[0].totalcurvaa.toFixed(0) }}
                </td>
              </tr>                        <!--  Total de Sku's da Curva A -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva A</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curva[0].perccurvaa['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.curva[0].totalperca.toFixed(2) }}%
                </td>
              </tr>                        <!--  % Ruptura da Curva A -->
              <tr v-if="totalizadores.rupturaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva B/C</td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(1, 'RB', 'Ivaiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja1 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(2, 'RB', 'Pitanga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja2 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(3, 'RB', 'São João do Ivaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja3 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(4, 'RB', 'Castelo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja4 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(5, 'RB', 'Candido')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja5 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(7, 'RB', 'Apucarana')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja7 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(8, 'RB', 'Jardim Alegre')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja8 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(9, 'RB', 'Manoel Ribas')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja9 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(10, 'RB', 'Faxinal')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja10 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(11, 'RB', 'Campo Mourão')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja11 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(14, 'RB', 'Cianorte')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja14 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(15, 'RB', 'Paranavaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja15 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(16, 'RB', 'Telêmaco')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja16 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(17, 'RB', 'Roncador')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja17 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(18, 'RB', 'Guarapuava')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja18 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(19, 'RB', 'Palmital')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja19 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(20, 'RB', 'Ibiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja20 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(21, 'RB', 'Turvo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja21 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(23, 'RB', 'São Mateus')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja23 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(24, 'RB', 'São João do Triunfo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja24 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(26, 'RB', 'Pontal do Paraná')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja26 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(27, 'RB', 'Mandaguari')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja27 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(28, 'RB', 'Astorga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja28 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(30, 'RB', 'Cambé')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja30 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(31, 'RB', 'Reserva')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja31 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(35, 'RB', 'Rolândia')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja35 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(36, 'RB', 'Maringá')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja36 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(38, 'RB', 'Laranjeiras')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja38 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(39, 'RB', 'E-commerce - CD')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja39 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center barra-total-left" nowrap >
                  {{ totalizadores.rupturaBC[0].totalrupturabc }}
                </td>
              </tr>                     <!--  Sku's em Ruptura da Curva B/C -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva B/C</td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(1, 'CB', 'Ivaiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja1.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(2, 'CB', 'Pitanga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja2.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(3, 'CB', 'São João do Ivaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja3.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(4, 'CB', 'Castelo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja4.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(5, 'CB', 'Candido')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja5.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(7, 'CB', 'Apucarana')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja7.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(8, 'CB', 'Jardim Alegre')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja8.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(9, 'CB', 'Manoel Ribas')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja9.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(10, 'CB', 'Faxinal')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja10.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(11, 'CB', 'Campo Mourão')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja11.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(14, 'CB', 'Cianorte')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja14.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(15, 'CB', 'Paranavaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja15.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(16, 'CB', 'Telêmaco')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja16.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(17, 'CB', 'Roncador')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja17.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(18, 'CB', 'Guarapuava')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja18.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(19, 'CB', 'Palmital')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja19.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(20, 'CB', 'Ibiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja20.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(21, 'CB', 'Turvo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja21.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(23, 'CB', 'São Mateus')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja23.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(24, 'CB', 'São João do Triunfo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja24.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(26, 'CB', 'Pontal do Paraná')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja26.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(27, 'CB', 'Mandaguari')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja27.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(28, 'CB', 'Astorga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja28.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(30, 'CB', 'Cambé')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja30.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(31, 'CB', 'Reserva')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja31.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(35, 'CB', 'Rolândia')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja35.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(36, 'CB', 'Maringá')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja36.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(38, 'CB', 'Laranjeiras')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja38.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(39, 'CB', 'E-commerce - CD')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja39.toFixed(0) }}
                      </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center barra-total-left" nowrap >
                  {{ totalizadores.curva[0].totalbc.toFixed(0) }}
                </td>
              </tr>                        <!--  Total de Sku's da Curva B/C-->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva B/C</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curva[0].perccurvabc['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.curva[0].totalpercbc.toFixed(2) }}%
                </td>
              </tr>                        <!--  % Ruptura da Curva B/C -->
              <tr v-if="totalizadores.rupturaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva (A,B,C)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.rupturaGeral[0].lojas['loja' + n] }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.rupturaGeral[0].totalruptura }}
                </td>
              </tr>                 <!--  Sku's em Ruptura da Curva (A,B,C) -->
              <tr v-if="totalizadores.curvaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% de Ruptura Total (A,B,C)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curvaGeral[0].lojas['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.curvaGeral[0].totalcurva.toFixed(2) }}%
                </td>
              </tr>                   <!--  % de Ruptura Total (A,B,C) -->
              <tr v-if="totalizadores.curvaGeral[0] && !carregandoTotais2" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                       <!-- ......................  -->
            </tfoot>
          </v-simple-table>
          <v-simple-table light v-else-if="vendasSecao[0] && !carregando" fixed-header height="50vh">
            <thead ref="thead">
              <tr class="border-line-head">
                <th class="text-center fixed-column-header white--text" nowrap>
                  <v-row class="pb-0" no-gutters>
                    <v-col cols="3" class="pa-0" style="display: flex; justify-content: start; align-items: start;">
                      <div>
                        <v-tooltip bottom color="primary" class="text-no-wrap">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="carregandoTotais" v-bind="attrs" v-on="on" @click="dialog = true" elevation="0" class="primary mt-n1 ml-n4 pa-0" x-small style="border-radius: 0px;" height="39px" dark><v-icon class="mt-1">mdi-arrow-expand-all</v-icon></v-btn>
                          </template>
                          <span>Visualizar em tela cheia</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="9" class="pa-0" style="display: flex; justify-content: center; align-items: center;">
                      <div class="mr-11">
                        DATA
                      </div>
                    </v-col>
                  </v-row>
                </th>
                <th class="text-center white--text" nowrap>MATERIAL BRUTO</th>
                <th class="text-center white--text" nowrap>PISOS REVESTIMENTOS</th>
                <th class="text-center white--text" nowrap>QUÍMICOS TINTAS ACESSORIOS</th>
                <th class="text-center white--text" nowrap>MATERIAL ELÉTRICO</th>
                <th class="text-center white--text" nowrap>ILUMINAÇÃO</th>
                <th class="text-center white--text" nowrap>HIDRÁULICA</th>
                <th class="text-center white--text" nowrap>PORTAS JANELAS</th>
                <th class="text-center white--text" nowrap>MATERIAL SANITÁRIO</th>
                <th class="text-center white--text" nowrap>LOUÇAS</th>
                <th class="text-center white--text" nowrap>MÓVEIS GABINETES</th>
                <th class="text-center white--text" nowrap>FERRAGENS</th>
                <th class="text-center white--text" nowrap>FERRAMENTAS</th>
                <th class="text-center white--text" nowrap>UTILIDADES DOMÉSTICAS</th>
                <th class="text-center white--text" nowrap>JARDINAGEM LAZER</th>
                <th class="text-center white--text" nowrap>AQUECIMENTO REFRIGERAÇÃO</th>
                <th class="text-center white--text" nowrap>MADEIRAS COBERTURA</th>
                <th class="text-center white--text" nowrap>FORA LINHA</th>
                <th class="text-center white--text" nowrap>CONCRETO</th>
                <th class="text-center white--text" nowrap>ENERGIA SOLAR</th>
                <th class="text-center white--text" nowrap>OUTLET</th>
                <th class="text-center white--text" nowrap>USO CONSUMO</th>
                <th class="text-center white--text barra-total-left" nowrap>TOTAL</th>
              </tr>
            </thead>
            <tbody v-if="vendasSecao[0]">
              <tr v-for="(u, i) in vendasSecao" :key="i" class="border-line">
              <td class="px-2 text-center fixed-column" nowrap> {{ u.dtmovimento }}</td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MATERIAL_BRUTO<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MATERIAL_BRUTO | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.PISOS_REVESTIMENTOS<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.PISOS_REVESTIMENTOS | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.QUÍMICOS_TINTAS_ACESSORIOS<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.QUÍMICOS_TINTAS_ACESSORIOS | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MATERIAL_ELETRICO<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MATERIAL_ELETRICO | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.ILUMINACAO<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.ILUMINACAO | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.HIDRAULICA<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.HIDRAULICA | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.PORTAS_JANELAS<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.PORTAS_JANELAS | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MATERIAL_SANITARIO<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MATERIAL_SANITARIO | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.LOUCAS<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.LOUCAS | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MOVEIS_GABINETES<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MOVEIS_GABINETES | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.FERRAGENS<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.FERRAGENS | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.FERRAMENTAS<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.FERRAMENTAS | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.UTILIDADES_DOMESTICAS<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.UTILIDADES_DOMESTICAS | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.JARDINAGEM_LAZER<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.JARDINAGEM_LAZER | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.AQUECIMENTO_REFRIGERACAO<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.AQUECIMENTO_REFRIGERACAO | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MADEIRAS_COBERTURA<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MADEIRAS_COBERTURA | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.FORA_LINHA<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.FORA_LINHA | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.CONCRETO<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.CONCRETO | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.ENERGIA_SOLAR<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.ENERGIA_SOLAR | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.OUTLET<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.OUTLET | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.USO_CONSUMO<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.secao.USO_CONSUMO | formataDinheiroTabela }} </span>
                </div>
              </td>
              <td class="px-2 text-right barra-total-left column-totais" nowrap>
                <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ u.totaldia | formataDinheiroTabela }} </span>
                </div>
              </td>
            </tr>
            </tbody>
            <tfoot v-if="totalizadoresSecao.vendas[0]">
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.variacoes.vendaLiquida[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Liquida</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaLiquida[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaLiquida[0].totalvendaliquida | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Venda Liquida -->
              <tr v-if="totalizadoresSecao.vendas[0]" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Bruta</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendas[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.vendas[0].totalsecaoempresa<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendas[0].totalsecaoempresa | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Venda Bruta -->
              <tr v-if="totalizadoresSecao.perparticSecao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Seção</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perparticSecao[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perparticSecao[0].totalsecaoempresa.toFixed(2) }}%
                </td>
              </tr><!-- % Partic. Seção -->
              <tr v-if="totalizadoresSecao.perparticRegiao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Regional</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perparticRegiao[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perparticRegiao[0].totalpartcregionalgeral.toFixed(2) }}%
                </td>
              </tr><!-- % Partic. Regional -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.variacoes.vendaAnoAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Ano Ant.</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaAnoAnt[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaAnoAnt[0].totalvendalastyear | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Venda Liq. Ano Ant. -->
              <tr v-if="totalizadoresSecao.variacoes.variacaoNominal[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Nominal</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacaoNominal[0].secao[s])">
                    {{ totalizadoresSecao.variacoes.variacaoNominal[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacaoNominal[0].variacaonominal)">
                    {{ totalizadoresSecao.variacoes.variacaoNominal[0].variacaonominal.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- % Variação Nominal -->
              <tr v-if="totalizadoresSecao.variacoes.vendaMesAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Mês Ant.</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaMesAnt[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaMesAnt[0].totalvendalastmonth | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Venda Liq. Mês Ant. -->
              <tr v-if="totalizadoresSecao.variacoes.variacao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Mês Ant.</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacao[0].secao[s])">
                    {{ totalizadoresSecao.variacoes.variacao[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacao[0].variacao)">
                    {{ totalizadoresSecao.variacoes.variacao[0].variacao.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- % Variação Mês Ant. -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.vendasObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Vendas de Obsoletos (R$)
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="red"
                          class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Apoio a decisão</span>
                  </v-tooltip>
                </td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendasObsoletos[0].secoes[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendasObsoletos[0].total_venda_obsoleto | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Vendas de Obsoletos (R$) -->
              <tr v-if="totalizadoresSecao.vendasObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Margem de Obsoletos (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.vendasObsoletos[0].margens[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.vendasObsoletos[0].margem_venda_obsoleto.toFixed(2) }}%
                </td>
              </tr><!--  Margem de Obsoletos (%) -->
              <tr v-if="totalizadoresSecao.estoqueObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque de Obsoletos</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.estoqueObsoletos[0].secoes[s] | formataDinheiro }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.estoqueObsoletos[0].total_estoque | formataDinheiro }}
                </td>
              </tr>   <!--  Estoque de Obsoletos -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.devolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Devoluções (R$)</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.devolucao[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.devolucao[0].totaldevolucao | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Devoluções (R$) -->
              <tr v-if="totalizadoresSecao.perDevolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Total Devoluções (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perDevolucao[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perDevolucao[0].totaldevolucao.toFixed(2) }}%
                </td>
              </tr><!--  Total Devoluções (%) -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.descontos.desconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (R$)</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.descontos.desconto[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.descontos.desconto[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.descontos.desconto[0].totaldesconto | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Desconto -->
              <tr v-if="totalizadoresSecao.descontos.perdesconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.descontos.perdesconto[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.descontos.perdesconto[0].totaldesconto.toFixed(2) }}%
                </td>
              </tr><!-- Total de Descontos (%) -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.margemMeta[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.margemMeta[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.margemMeta[0].totalmargemmetafilial.toFixed(2) }}%
                </td>
              </tr>    <!--  Margem Meta -->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr v-if="totalizadoresSecao.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Realizada Acum.</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margem[0].secao[s])">
                    {{ totalizadoresSecao.margem[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margem[0].totalmargemgeral)">
                    {{ totalizadoresSecao.margem[0].totalmargemgeral.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- Margem Realizada Acum. -->
              <tr v-if="totalizadoresSecao.margemAnoAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Ano Anterior</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margemAnoAnt[0].secao[s])">
                    {{ totalizadoresSecao.margemAnoAnt[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margemAnoAnt[0].totalmargemlastyeargeral)">
                    {{ totalizadoresSecao.margemAnoAnt[0].totalmargemlastyeargeral.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- Margem Ano Anterior -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Entrada Ajuste de Estoque</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.entradasSaidasAvulsa[0].lojasent[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].lojasent[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalentrada<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].totalentrada | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Entrada Ajuste de Estoque -->
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saída Ajuste de Estoque</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.entradasSaidasAvulsa[0].lojassaid[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].lojassaid[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalsaida<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].totalsaida | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Saída Ajuste de Estoque -->
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saldo de Ajustes</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.entradasSaidasAvulsa[0].saldo[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].saldo[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalsaldo<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].totalsaldo | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Saldo de Ajustes -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.estoqueAnoAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Estoque Ano Anterior</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.estoqueAnoAnt[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueAnoAnt[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.estoqueAnoAnt[0].totalestoqueinicial<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueAnoAnt[0].totalestoqueinicial | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Estoque Ano Anterior -->
              <tr v-if="totalizadoresSecao.diasEstoqueFuturo[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Futuro</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.diasEstoqueFuturo[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.diasEstoqueFuturo[0].totaldiasestoquefuturofilial }}
                </td>
              </tr><!--  Dias de Estoque - Futuro - Em desenvolvimento ...-->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Dias de Estoque - Futuro</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr v-if="totalizadoresSecao.diasEstoqueReal[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Real</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.diasEstoqueReal[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.diasEstoqueReal[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.diasEstoqueReal[0].totaldiasestoquerealfilial<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.diasEstoqueReal[0].totaldiasestoquerealfilial | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!--  Dias de Estoque - Real - Em desenvolvimento ...-->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Dias de Estoque - Real</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr v-if="totalizadoresSecao.estoqueInicial[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Inicial</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.estoqueInicial[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueInicial[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.estoqueInicial[0].totalestoqueinicial<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueInicial[0].totalestoqueinicial | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Estoque Inicial -->
              <tr v-if="totalizadoresSecao.movimentacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Movimentações</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.movimentacoes[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.movimentacoes[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.movimentacoes[0].totalmovimentacoes<0?'color:red;':''">
                  <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.movimentacoes[0].totalmovimentacoes | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Movimentacoes -->
              <tr v-if="totalizadoresSecao.estoqueFinal[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Final</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.estoqueFinal[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueFinal[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.estoqueFinal[0].totalestoquefinal<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueFinal[0].totalestoquefinal | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Estoque Final -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Saldo Pedidos de Compra
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="primary"
                          class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Custo Última Compra</span>
                  </v-tooltip>
                </td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.pedidos[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.pedidos[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.pedidos[0].totalpedido<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.pedidos[0].totalpedido | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>   <!--  Saldo Pedidos -->
              <tr v-if="totalizadoresSecao.itensAtivos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.itensAtivos[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.itensAtivos[0].totalitensativosfilial}}
                </td>
              </tr>   <!--  Total de Sku's em Estoque -->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr v-if="totalizadoresSecao.itensEncalhados[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.itensEncalhados[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.itensEncalhados[0].totalitensencalhadosfilial.toFixed(2) }}%
                </td>
              </tr>   <!--  Sku's Encalhados -->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.rupturaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva A</td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1001, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MATERIAL_BRUTO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1002, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.PISOS_REVESTIMENTOS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1003, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.QUÍMICOS_TINTAS_ACESSORIOS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1004, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MATERIAL_ELETRICO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1005, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.ILUMINACAO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1006, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.HIDRAULICA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1007, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.PORTAS_JANELAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1008, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MATERIAL_SANITARIO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1009, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.LOUCAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1010, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MOVEIS_GABINETES }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1011, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.FERRAGENS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1012, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.FERRAMENTAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1013, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.UTILIDADES_DOMESTICAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1014, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.JARDINAGEM_LAZER }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1015, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.AQUECIMENTO_REFRIGERACAO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1016, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MADEIRAS_COBERTURA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1018, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.FORA_LINHA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1020, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.CONCRETO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1021, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.ENERGIA_SOLAR }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1019, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.OUTLET }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1017, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.USO_CONSUMO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaA[0].totalrupturaafilial }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva A-->
              <tr v-if="totalizadoresSecao.curvaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A</td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1001, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MATERIAL_BRUTO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1002, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.PISOS_REVESTIMENTOS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1003, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.QUÍMICOS_TINTAS_ACESSORIOS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1004, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MATERIAL_ELETRICO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1005, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.ILUMINACAO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1006, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.HIDRAULICA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1007, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.PORTAS_JANELAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1008, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MATERIAL_SANITARIO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1009, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.LOUCAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1010, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MOVEIS_GABINETES.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1011, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.FERRAGENS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1012, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.FERRAMENTAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1013, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.UTILIDADES_DOMESTICAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1014, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.JARDINAGEM_LAZER.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1015, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.AQUECIMENTO_REFRIGERACAO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1016, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MADEIRAS_COBERTURA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1018, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.FORA_LINHA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1020, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.CONCRETO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1021, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.ENERGIA_SOLAR.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1019, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.OUTLET.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1017, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.USO_CONSUMO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                    {{ totalizadoresSecao.curvaA[0].curvaa.toFixed(0) }}
                </td>
              </tr><!-- Total de Sku's da Curva A-->
              <tr v-if="totalizadoresSecao.perCurvaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva A</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perCurvaA[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.perCurvaA[0].TotalPerccurvaa.toFixed(2) }}%
                </td>
              </tr><!-- % Ruptura da Curva A-->
              <tr v-if="totalizadoresSecao.rupturaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva B/C</td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1001, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MATERIAL_BRUTO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1002, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.PISOS_REVESTIMENTOS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1003, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.QUÍMICOS_TINTAS_ACESSORIOS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1004, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MATERIAL_ELETRICO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1005, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.ILUMINACAO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1006, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.HIDRAULICA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1007, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.PORTAS_JANELAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1008, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MATERIAL_SANITARIO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1009, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.LOUCAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1010, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MOVEIS_GABINETES }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1011, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.FERRAGENS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1012, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.FERRAMENTAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1013, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.UTILIDADES_DOMESTICAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1014, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.JARDINAGEM_LAZER }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1015, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.AQUECIMENTO_REFRIGERACAO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1016, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MADEIRAS_COBERTURA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1018, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.FORA_LINHA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1020, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.CONCRETO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1021, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.ENERGIA_SOLAR }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1019, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.OUTLET }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1017, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.USO_CONSUMO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaBC[0].totalrupturaBcfilial }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva B/C-->
              <tr v-if="totalizadoresSecao.curvaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva B/C</td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1001, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MATERIAL_BRUTO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1002, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.PISOS_REVESTIMENTOS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1003, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.QUÍMICOS_TINTAS_ACESSORIOS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1004, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MATERIAL_ELETRICO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1005, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.ILUMINACAO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1006, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.HIDRAULICA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1007, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.PORTAS_JANELAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1008, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MATERIAL_SANITARIO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1009, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.LOUCAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1010, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MOVEIS_GABINETES.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1011, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.FERRAGENS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1012, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.FERRAMENTAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1013, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.UTILIDADES_DOMESTICAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1014, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.JARDINAGEM_LAZER.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1015, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.AQUECIMENTO_REFRIGERACAO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1016, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MADEIRAS_COBERTURA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1018, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.FORA_LINHA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1020, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.CONCRETO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1021, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.ENERGIA_SOLAR.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1019, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.OUTLET.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1017, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.USO_CONSUMO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.curvaBC[0].Totalcurvabc.toFixed(0) }}
                </td>
              </tr><!-- Total de Sku's da Curva B/C-->
              <tr v-if="totalizadoresSecao.perCurvaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva B/C</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perCurvaBC[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perCurvaBC[0].Totalcurvabc.toFixed(2) }}%
                </td>
              </tr><!-- % Ruptura da Curva B/C-->
              <tr v-if="totalizadoresSecao.rupturaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva (A,B,C)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.rupturaGeral[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaGeral[0].totalruptura }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva (A,B,C)-->
              <tr v-if="totalizadoresSecao.curvaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% de Ruptura Total (A,B,C)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.curvaGeral[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.curvaGeral[0].totalcurvafilialloja.toFixed(2) }}%
                </td>
              </tr><!-- % de Ruptura Total (A,B,C)-->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
            </tfoot>
          </v-simple-table>
          <v-alert class="error mb-0" v-else-if="!carregando">Nenhum registro encontrado.</v-alert>
        </v-card-text>
        <v-row class="px-7 pb-1" v-if="carregandoTotais2">
          <v-progress-circular
              class="mt-1 mr-1"
              indeterminate
              color="white"
              :size="12"
          ></v-progress-circular>
          <span class="text-caption font-italic">Carregando totais...</span>
        </v-row>
			</v-card>
		</v-col>
    <v-dialog v-model="dialog" fullscreen transition="dialog-transition">
      <v-card>
        <v-sheet class="overflow-hidden" style="position: relative;">
          <v-navigation-drawer
              class="primary"
              v-model="sheet"
              absolute
              temporary
              dark
          >
            <v-col cols="12" class="px-4">
              <v-row class="pa-3">
                <v-toolbar-title>
                  <router-link to="/">
                    <v-img max-height="40" max-width="155" src="../../assets/icons/Gestor_Logo_.svg" />
                  </router-link>
                </v-toolbar-title>
            </v-row>
            </v-col>
            <v-divider/>
            <v-col cols="12" class="px-3 pt-3 pb-2">
              <v-select
                  label="Período"
                  filled
                  dense
                  :items="periodos"
                  item-text="data"
                  item-value="data"
                  :disabled="carregando"
                  v-model="busca.periodo.data"
                  hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" class="px-3 py-2">
              <v-select
                  label="Filial"
                  filled
                  dense
                  :items="filiaisFiltro"
                  item-text="filial"
                  item-value="idfilial"
                  :disabled="carregando"
                  v-model="busca.idempresa"
                  hide-details
                  clearable
              ></v-select>
            </v-col>
            <v-col cols="12" class="px-3 py-2" v-show="(!busca.idempresa)">
              <v-select
                  label="Regional"
                  filled
                  dense
                  :items="regionais"
                  item-text="regional"
                  item-value="idregional"
                  :disabled="carregando"
                  v-model="busca.idregional"
                  hide-details
                  clearable
              ></v-select>
            </v-col>
            <v-col cols="12" class="px-3 py-2" v-show="(!busca.idempresa)">
              <v-select
                  label="Seção"
                  filled
                  dense
                  :items="secoes"
                  item-text="descr"
                  item-value="idsecao"
                  :disabled="carregando"
                  v-model="busca.idsecao"
                  hide-details
                  clearable
              ></v-select>
            </v-col>
            <v-col class="px-3 pt-2 pb-1">
              <v-btn width="100%" light @click="listarVendas(), sheet = !sheet">Filtrar</v-btn>
            </v-col>
            <v-col class="px-3 pt-2">
              <v-btn width="100%" light @click="sheet = !sheet"><span style="color: red">Cancelar</span></v-btn>
            </v-col>
          </v-navigation-drawer>
          <v-card-text class="pa-0">
            <v-skeleton-loader light v-if="carregando || carregandoTotais" type="table-tbody" />
            <v-skeleton-loader light v-if="carregando || carregandoTotais" type="table-tbody" />
            <v-skeleton-loader light v-if="carregando || carregandoTotais" type="table-tbody" />
            <v-simple-table light v-else-if="vendasLojas[0] && !carregando" fixed-header height="100vh">
              <thead ref="thead">
              <tr class="border-line-head">
                <th class="fixed-column-header white--text text-center" nowrap>
                  <v-row class="pb-0" no-gutters>
                    <v-col cols="1" class="pa-0" style="display: flex; justify-content: start; align-items: start;">
                      <v-row class="pb-0" no-gutters>
                        <div>
                          <v-tooltip bottom color="error" class="text-no-wrap">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" @click="dialog = false" elevation="0" class="red ml-n4 pa-0" x-small style="border-radius: 2px;" height="28px" dark><v-icon size="20px" class="mt-1">mdi-arrow-left-bold</v-icon></v-btn>
                            </template>
                            <span>Fechar tela cheia</span>
                          </v-tooltip>
                        </div>
                        <div>
                          <v-tooltip bottom color="primary" class="text-no-wrap">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" @click="sheet= !sheet" elevation="0" class="primary ml-n4 pa-0" x-small style="border-radius: 2px;" height="30px" dark><v-icon small class="mt-1">mdi-filter-menu</v-icon></v-btn>
                            </template>
                            <span>Menu Filtros</span>
                          </v-tooltip>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="11" class="pa-0" style="display: flex; justify-content: center; align-items: center;">
                      <div class="mr-9 text-subtitle-2 font-weight-medium">
                        DATA
                      </div>
                    </v-col>
                  </v-row>
                </th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('1')">IVAIPORÃ</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('2')">PITANGA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('3')">SJ. IVAÍ</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('4')">CASTELO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('5')">CANDIDO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('7')">APUCARANA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('8')">J. ALEGRE</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('9')">M. RIBAS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('10')">FAXINAL</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('11')">C. MOURÃO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('14')">CIANORTE</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('15')">PARANAVAÍ</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('16')">TELÊMACO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('17')">RONCADOR</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('18')">GUARAPUAVA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('19')">PALMITAL</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('20')">IBIPORÃ</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('21')">TURVO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('23')">S. MATEUS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('24')">SJ. TRIUNFO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('26')">PONTAL</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('27')">MANDAGUARI</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('28')">ASTORGA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('30')">CAMBÉ</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('31')">RESERVA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('35')">ROLÂNDIA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('36')">MARINGÁ</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('38')">LARANJEIRAS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap v-if="validarNumero('39')">CD ECOMMERCE</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium barra-total-left" nowrap>TOTAL</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(u, i) in vendasLojas" :key="i" class="border-line">
                <td class="px-2 text-center fixed-column" nowrap> {{ u.data | formataData}} </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(1)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja1<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja1 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(2)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja2<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja2 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(3)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja3<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja3 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(4)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja4<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja4 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(5)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja5<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja5 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(7)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja7<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja7 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(8)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja8<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja8 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(9)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja9<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja9 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(10)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja10<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja10 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(11)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja11<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja11 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(14)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja14<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja14 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(15)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja15<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja15 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(16)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja16<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja16 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(17)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja17<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja17 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(18)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja18<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja18 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(19)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja19<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja19 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(20)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja20<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja20 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(21)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja21<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja21 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(23)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja23<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja23 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(24)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja24<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja24 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(26)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja26<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja26 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(27)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja27<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja27 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(28)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja28<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja28 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(30)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja30<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja30 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(31)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja31<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja31 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(35)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja35<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja35 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(36)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja36<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja36 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(38)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja38<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja38 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap v-if="validarNumero(39)">
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.lojas.loja39<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.lojas.loja39 | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left column-totais" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.total_venda_bruta_dia <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.total_venda_bruta_dia | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot v-if="vendasLojas[0] && vendasLojas[0]">
              <tr v-if="totalizadores.vendas.totaloja[0]" class="barra-total-top">
                <td colspan="100%" style="height: 3px !important"></td>
              </tr>
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Líquida</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendaliquida['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendaliquida['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.variacoes[0].totalvendaliquida<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.variacoes[0].totalvendaliquida | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>                     <!--  Total Venda Líquida -->
              <tr v-if="totalizadores.vendas.totaloja[0]" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Bruta</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.vendas.totaloja[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.vendas.totaloja[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.vendas.totalvendaLiquida <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.vendas.totalvendaLiquida | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                                    <!--  Total Venda Bruta -->
              <tr v-if="totalizadores.vendas.perclojas[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Geral</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.vendas.perclojas[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.vendas.perclojas[0].lojas['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap :style="totalizadores.vendas.perclojas[0].totalparticipacaogeral<0?'color:#F22233;':''">
                  {{ totalizadores.vendas.perclojas[0].totalparticipacaogeral.toFixed(2) }}%
                </td>
              </tr>              <!--  % Partic. Geral -->
              <tr v-if="totalizadores.vendas.percpartregionais[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Regional</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.vendas.percpartregionais[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.vendas.percpartregionais[0].lojas['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap  :style="totalizadores.vendas.percpartregionais[0].totalpartregional<0?'color:#F22233;':''">
                  {{ totalizadores.vendas.percpartregionais[0].totalpartregional.toFixed(2) }}%
                </td>
              </tr>      <!--  % Partic. Região -->
              <tr v-if="totalizadores.metasMes[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                          <!-- ......................  -->
              <tr v-if="totalizadores.metasMes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Meta de Venda - Mês</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasMes[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasMes[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.metasMes[0].totalmeta<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.metasMes[0].totalmeta | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>                      <!--  Meta de venda - Mes -->
              <tr v-if="totalizadores.metasDia[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Meta de Venda Acumulada</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasDia[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasDia[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.metasDia[0].totalmeta<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.metasDia[0].totalmetadia | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>                      <!--  Meta de venda Acumulada -->
              <tr v-if="totalizadores.metasVariacao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">% Variação de Meta</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarMeta(totalizadores.metasVariacao[0].lojas['loja' + n])">
                    {{ totalizadores.metasVariacao[0].lojas['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :class="coresVarMeta(totalizadores.metasVariacao[0].TotalMetaVariacao)">
                    {{ totalizadores.metasVariacao[0].TotalMetaVariacao.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                 <!--  % Variação de Meta -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compvariacao">Venda Liq. Ano Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendabrutaanoanterior['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendabrutaanoanterior['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.variacoes[0].totalvendabrutaanoanterior<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.variacoes[0].totalvendabrutaanoanterior | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                     <!--  Venda Liq. Ano Ant. -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Nominal</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadores.variacoes[0].variacaonominalperc['loja' + n])">
                    {{ totalizadores.variacoes[0].variacaonominalperc['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :class="coresVarariacoes(totalizadores.variacoes[0].totalpercvariacaonominal)">
                    {{ totalizadores.variacoes[0].totalpercvariacaonominal.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                     <!--  % Var. Nominal -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Mês Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendabrutamesanterior['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendabrutamesanterior['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.variacoes[0].totalvendabrutamesanterior <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span> {{totalizadores.variacoes[0].totalvendabrutamesanterior | formataDinheiroTabela}} </span>
                  </div>
                </td>
              </tr>                     <!--  Venda Liq. Mês Ant. -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Mês Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadores.variacoes[0].vendabrutavariacaomes['loja' + n])">
                    {{ totalizadores.variacoes[0].vendabrutavariacaomes['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :color="coresVarariacoes(totalizadores.variacoes[0].totalpercvariacao)">
                    {{ totalizadores.variacoes[0].totalpercvariacao.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                     <!--  % Var. Mês Ant. -->
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                         <!-- ......................  -->
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compdevolucao">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Vendas de Obsoletos (R$)
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="red"
                          class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Apoio a decisão</span>
                  </v-tooltip>
                </td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.vendasObsoletos[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.vendasObsoletos[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.vendasObsoletos[0].total_venda_obsoleto | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                     <!--  Vendas de Obsoletos (R$) -->
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Margem de Obsoletos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :color="coresMargem(totalizadores.vendasObsoletos[0].margens['loja' + n])">
                    {{ totalizadores.vendasObsoletos[0].margens['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :color="coresMargem(totalizadores.vendasObsoletos[0].margem_venda_obsoleto)">
                    {{ totalizadores.vendasObsoletos[0].margem_venda_obsoleto.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                  <!--  Margem Obsoletos (%) -->
              <tr v-if="totalizadores.estoqueObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque de Obsoletos</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.estoqueObsoletos[0].lojas['loja' + n] | formataDinheiro }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.estoqueObsoletos[0].total_estoque | formataDinheiro }}
                </td>
              </tr>               <!--  Estoque de Obsoletos - Em desenvolvimento ...-->
              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                         <!-- ......................  -->
              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compdevolucao">Total Devoluções (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.devolucao[0].devolucao['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.devolucao[0].devolucao['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.devolucao[0].totaldevolucao | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                     <!--  Total Devoluções (R$) -->
              <tr v-if="totalizadores.perDevolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Total Devoluções (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.perDevolucao[0].devolucao['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.perDevolucao[0].devolucao['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.perDevolucao[0].totaldevolucao.toFixed(2) }}%
                </td>
              </tr>                  <!--  Total Devoluções (%) -->
              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                         <!-- ......................  -->
              <tr v-if="totalizadores.metasDesconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Metas de Descontos (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasDesconto[0].metadescontoreal['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasDesconto[0].metadescontoreal['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.metasDesconto[0].metatotalreal <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.metasDesconto[0].metatotalreal | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Metas de Descontos (R$) -->
              <tr v-if="totalizadores.metasPerDesconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Metas de Descontos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.metasPerDesconto[0].metadesconto['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.metasPerDesconto[0].metadesconto['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.metasPerDesconto[0].metatotal.toFixed(2) }}%
                </td>
              </tr>              <!--  Metas de Descontos (%)  -->
              <tr v-if="totalizadores.desconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.desconto[0].valordesconto['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.desconto[0].valordesconto['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.totalvendabrutageral <0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.desconto[0].totaldesconto | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                      <!--  Total Desconto -->
              <tr v-if="totalizadores.desconto[0] && totalizadores.metasPerDesconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresPerDesconto(['loja' + n])">
                    {{ totalizadores.desconto[0].descontoconcedido['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <v-chip small label :class="totalizadores.desconto[0].totaldescontoconcedido>totalizadores.metasPerDesconto[0].metatotal?'red white--text':'blue darken-2 white--text'">
                    {{ totalizadores.desconto[0].totaldescontoconcedido.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>  <!--  % Desconto -->
              <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                            <!-- ......................  -->
              <tr v-if="totalizadores.margemMeta[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap :style="totalizadores.margemMeta[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.margemMeta[0].lojas['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap :style="totalizadores.margemMeta[0].totalmargemmeta<0?'color:#F22233;':''">
                  {{ totalizadores.margemMeta[0].totalmargemmeta.toFixed(2) }}%
                </td>
              </tr>                    <!--  Margem Meta - Em desenvolvimento ...-->
              <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Realizada Acum.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].margem['loja' + n])">
                    {{ totalizadores.margem[0].margem['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].margem.loja38)">
                    {{ totalizadores.margem[0].totalmargematual.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                        <!--  Margem Realizada Acum. -->
              <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Ano Anterior</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].margemanopassado['loja' + n])">
                    {{ totalizadores.margem[0].margemanopassado['loja' + n].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].totalmargemlastyear)">
                    {{ totalizadores.margem[0].totalmargemlastyear.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr>                        <!--  Margem Ano Anterior -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                           <!-- ......................  -->
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Entradas Avulsa</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].lojasent['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].lojasent['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.entradasSaidasAvulsa[0].totalentrada<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.entradasSaidasAvulsa[0].totalentrada | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Entrada Avulsa -->
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saidas Avulsa</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].lojassaid['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].lojassaid['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.entradasSaidasAvulsa[0].totalsaida<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.entradasSaidasAvulsa[0].totalsaida | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Saida Avulsa -->
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saldo</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].saldo['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].saldo['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.entradasSaidasAvulsa[0].totalsaldo<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.entradasSaidasAvulsa[0].totalsaldo | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                           <!-- ......................  -->
              <tr v-if="totalizadores.estoqueAnoAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Estoque Ano Anterior</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoqueAnoAnt[0].estoqued1['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoqueAnoAnt[0].estoqued1['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.estoqueAnoAnt[0].totalestoqued1<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoqueAnoAnt[0].totalestoqued1 | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                 <!--  Estoque Ano Anterior -->
              <tr v-if="totalizadores.diasEstoqueFuturo[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Futuro</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.diasEstoqueFuturo[0].lojas['loja' + n] }} dias
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.diasEstoqueFuturo[0].totaldiasestoquefuturo }} dias
                </td>
              </tr>             <!--  Dias de Estoque - Futuro - Em desenvolvimento ...-->
              <tr v-if="totalizadores.diasEstoqueReal[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Real</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.diasEstoqueReal[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.diasEstoqueReal[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.diasEstoqueReal[0].totaldiasestoquereal<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.diasEstoqueReal[0].totaldiasestoquereal | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>               <!--  Dias de Estoque - Real - Em desenvolvimento ...-->
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Inicial</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].estoqued2['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].estoqued2['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.estoque[0].totalestoqued2<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalestoqued2 | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>                       <!--  Estoque Inicial -->
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Movimentação</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].Movimentacoes['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].Movimentacoes['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.estoque[0].totalMovimentacoes<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalMovimentacoes | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                       <!--  Movimentação -->
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Final</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].estoqued1['loja' + n] < 0 ? 'color:#F22233;' : ''" class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].estoqued1['loja' + n] | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadores.estoque[0].totalestoqued1<0?'color:#F22233;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalestoqued1 | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>                       <!--  Estoque Final -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                           <!-- ......................  -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Saldo Pedidos de Compra
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="primary"
                          class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Custo Última Compra</span>
                  </v-tooltip>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(1, 'Ivaiporã')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja1 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(2, 'Pitanga')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja2 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(3, 'São João do Ivaí')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja3 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(4, 'Castelo')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja4 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(5, 'Candido')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja5 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(7, 'Apucarana')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja7 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(8, 'Jardim Alegre')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja8 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(9, 'Manoel Ribas')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja9 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(10, 'Faxinal')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja10 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(11, 'Campo Mourão')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja11 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(14, 'Cianorte')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja14 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(15, 'Paranavaí')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja15 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(16, 'Telêmaco')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja16 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(17, 'Roncador')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja17 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(18, 'Guarapuava')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja18 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(19, 'Palmital')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja19 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(20, 'Ibiporã')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja20 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(21, 'Turvo')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja21 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(23, 'São Mateus')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja23 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(24, 'São João do Triunfo')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja24 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(26, 'Pontal do Paraná')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja26 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(27, 'Mandaguari')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja27 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(28, 'Astorga')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja28 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(30, 'Cambé')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja30 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(31, 'Reserva')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja31 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(35, 'Rolândia')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja35 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(36, 'Maringá')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja36 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(38, 'Laranjeiras')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja38 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharPedidos(39, 'E-commerce - CD')" class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.pedidos[0].lojas.loja39 | formataDinheiroTabela}} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>R$</span><span>{{ totalizadores.pedidos[0].totalpedidos | formataDinheiroTabela}} </span>
                  </div>
                </td>
              </tr>                       <!--  Saldo Pedidos -->
              <tr v-if="totalizadores.itensAtivos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.itensAtivos[0].lojas['loja' + n] }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.itensAtivos[0].totalitensativos }}
                </td>
              </tr>                   <!--  Total de Sku's em Estoque -->
              <tr v-if="totalizadores.itensEncalhados[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.itensEncalhados[0].lojas['loja' + n] }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.itensEncalhados[0].totalitensencalhados }}
                </td>
              </tr>               <!--  Sku's Encalhados - Em desenvolvimento ...-->
              <tr v-if="totalizadores.rupturaA[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>                                          <!-- ......................  -->
              <tr v-if="totalizadores.rupturaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva A</td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(1, 'RA', 'Ivaiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja1 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(2, 'RA', 'Pitanga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja2 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(3, 'RA', 'São João do Ivaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja3 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(4, 'RA', 'Castelo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja4 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(5, 'RA', 'Candido')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja5 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(7, 'RA', 'Apucarana')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja7 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(8, 'RA', 'Jardim Alegre')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja8 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(9, 'RA', 'Manoel Ribas')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja9 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(10, 'RA', 'Faxinal')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja10 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(11, 'RA', 'Campo Mourão')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja11 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(14, 'RA', 'Cianorte')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja14 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(15, 'RA', 'Paranavaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja15 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(16, 'RA', 'Telêmaco')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja16 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(17, 'RA', 'Roncador')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja17 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(18, 'RA', 'Guarapuava')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja18 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(19, 'RA', 'Palmital')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja19 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(20, 'RA', 'Ibiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja20 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(21, 'RA', 'Turvo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja21 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(23, 'RA', 'São Mateus')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja23 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(24, 'RA', 'São João do Triunfo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja24 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(26, 'RA', 'Pontal do Paraná')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja26 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(27, 'RA', 'Mandaguari')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja27 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(28, 'RA', 'Astorga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja28 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(30, 'RA', 'Cambé')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja30 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(31, 'RA', 'Reserva')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja31 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(35, 'RA', 'Rolândia')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja35 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(36, 'RA', 'Maringá')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja36 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(38, 'RA', 'Laranjeiras')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja38 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(39, 'RA', 'E-commerce - CD')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaA[0].valordesconto.loja39 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center barra-total-left" nowrap >
                  {{ totalizadores.rupturaA[0].totalrupturaa }}
                </td>
              </tr>                     <!--  Sku's em Ruptura da Curva A -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A</td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(1, 'CA', 'Ivaiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja1.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(2, 'CA', 'Pitanga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja2.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(3, 'CA', 'São João do Ivaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja3.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(4, 'CA', 'Castelo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja4.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(5, 'CA', 'Candido')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja5.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(7, 'CA', 'Apucarana')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja7.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(8, 'CA', 'Jardim Alegre')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja8.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(9, 'CA', 'Manoel Ribas')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja9.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(10, 'CA', 'Faxinal')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja10.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(11, 'CA', 'Campo Mourão')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja11.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(14, 'CA', 'Cianorte')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja14.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(15, 'CA', 'Paranavaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja15.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(16, 'CA', 'Telêmaco')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja16.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(17, 'CA', 'Roncador')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja17.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(18, 'CA', 'Guarapuava')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja18.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(19, 'CA', 'Palmital')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja19.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(20, 'CA', 'Ibiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja20.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(21, 'CA', 'Turvo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja21.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(23, 'CA', 'São Mateus')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja23.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(24, 'CA', 'São João do Triunfo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja24.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(26, 'CA', 'Pontal do Paraná')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja26.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(27, 'CA', 'Mandaguari')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja27.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(28, 'CA', 'Astorga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja28.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(30, 'CA', 'Cambé')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja30.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(31, 'CA', 'Reserva')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja31.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(35, 'CA', 'Rolândia')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja35.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(36, 'CA', 'Maringá')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja36.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(38, 'CA', 'Laranjeiras')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja38.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(39, 'CA', 'E-commerce - CD')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvaa.loja39.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center barra-total-left" nowrap >
                  {{ totalizadores.curva[0].totalcurvaa.toFixed(0) }}
                </td>
              </tr>                        <!--  Total de Sku's da Curva A -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva A</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curva[0].perccurvaa['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.curva[0].totalperca.toFixed(2) }}%
                </td>
              </tr>                        <!--  % Ruptura da Curva A -->
              <tr v-if="totalizadores.rupturaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva B/C</td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(1, 'RB', 'Ivaiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja1 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(2, 'RB', 'Pitanga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja2 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(3, 'RB', 'São João do Ivaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja3 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(4, 'RB', 'Castelo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja4 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(5, 'RB', 'Candido')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja5 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(7, 'RB', 'Apucarana')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja7 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(8, 'RB', 'Jardim Alegre')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja8 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(9, 'RB', 'Manoel Ribas')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja9 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(10, 'RB', 'Faxinal')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja10 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(11, 'RB', 'Campo Mourão')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja11 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(14, 'RB', 'Cianorte')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja14 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(15, 'RB', 'Paranavaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja15 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(16, 'RB', 'Telêmaco')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja16 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(17, 'RB', 'Roncador')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja17 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(18, 'RB', 'Guarapuava')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja18 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(19, 'RB', 'Palmital')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja19 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(20, 'RB', 'Ibiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja20 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(21, 'RB', 'Turvo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja21 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(23, 'RB', 'São Mateus')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja23 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(24, 'RB', 'São João do Triunfo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja24 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(26, 'RB', 'Pontal do Paraná')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja26 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(27, 'RB', 'Mandaguari')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja27 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(28, 'RB', 'Astorga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja28 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(30, 'RB', 'Cambé')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja30 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(31, 'RB', 'Reserva')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja31 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(35, 'RB', 'Rolândia')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja35 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(36, 'RB', 'Maringá')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja36 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(38, 'RB', 'Laranjeiras')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja38 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(39, 'RB', 'E-commerce - CD')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.rupturaBC[0].lojas.loja39 }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center barra-total-left" nowrap >
                  {{ totalizadores.rupturaBC[0].totalrupturabc }}
                </td>
              </tr>                     <!--  Sku's em Ruptura da Curva B/C -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva B/C</td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(1)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(1, 'CB', 'Ivaiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja1.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(2)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(2, 'CB', 'Pitanga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja2.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(3)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(3, 'CB', 'São João do Ivaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja3.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(4)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(4, 'CB', 'Castelo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja4.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(5)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(5, 'CB', 'Candido')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja5.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(7)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(7, 'CB', 'Apucarana')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja7.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(8)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(8, 'CB', 'Jardim Alegre')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja8.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(9)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(9, 'CB', 'Manoel Ribas')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja9.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(10)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(10, 'CB', 'Faxinal')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja10.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(11)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(11, 'CB', 'Campo Mourão')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja11.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(14)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(14, 'CB', 'Cianorte')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja14.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(15)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(15, 'CB', 'Paranavaí')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja15.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(16)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(16, 'CB', 'Telêmaco')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja16.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(17)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(17, 'CB', 'Roncador')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja17.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(18)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(18, 'CB', 'Guarapuava')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja18.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(19)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(19, 'CB', 'Palmital')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja19.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(20)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(20, 'CB', 'Ibiporã')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja20.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(21)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(21, 'CB', 'Turvo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja21.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(23)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(23, 'CB', 'São Mateus')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja23.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(24)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(24, 'CB', 'São João do Triunfo')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja24.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(26)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(26, 'CB', 'Pontal do Paraná')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja26.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(27)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(27, 'CB', 'Mandaguari')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja27.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(28)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(28, 'CB', 'Astorga')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja28.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(30)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(30, 'CB', 'Cambé')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja30.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(31)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(31, 'CB', 'Reserva')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja31.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(35)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(35, 'CB', 'Rolândia')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja35.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(36)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(36, 'CB', 'Maringá')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja36.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(38)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(38, 'CB', 'Laranjeiras')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja38.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap v-if="validarNumero(39)">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRuptura(39, 'CB', 'E-commerce - CD')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadores.curva[0].itenscurvabc.loja39.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center barra-total-left" nowrap >
                  {{ totalizadores.curva[0].totalbc.toFixed(0) }}
                </td>
              </tr>                        <!--  Total de Sku's da Curva B/C-->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva B/C</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curva[0].perccurvabc['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.curva[0].totalpercbc.toFixed(2) }}%
                </td>
              </tr>                        <!--  % Ruptura da Curva B/C -->
              <tr v-if="totalizadores.rupturaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva (A,B,C)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.rupturaGeral[0].lojas['loja' + n] }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.rupturaGeral[0].totalruptura }}
                </td>
              </tr>                 <!--  Sku's em Ruptura da Curva (A,B,C) -->
              <tr v-if="totalizadores.curvaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% de Ruptura Total (A,B,C)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curvaGeral[0].lojas['loja' + n].toFixed(2) }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap >
                  {{ totalizadores.curvaGeral[0].totalcurva.toFixed(2) }}%
                </td>
              </tr>                   <!--  % de Ruptura Total (A,B,C) -->
              <tr v-if="totalizadores.curvaGeral[0] && !carregandoTotais2" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              </tfoot>
            </v-simple-table>
            <v-simple-table light v-else-if="vendasSecao[0] && !carregando" fixed-header height="100vh">
              <thead ref="thead">
              <tr class="border-line-head">
                <th class="fixed-column-header white--text text-center" nowrap>
                  <v-row class="pb-0" no-gutters>
                    <v-col cols="1" class="pa-0" style="display: flex; justify-content: start; align-items: start;">
                      <v-row class="pb-0" no-gutters>
                        <div>
                          <v-tooltip bottom color="error" class="text-no-wrap">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" @click="dialog = false" elevation="0" class="red ml-n4 pa-0" x-small style="border-radius: 2px;" height="28px" dark><v-icon size="20px" class="mt-1">mdi-arrow-left-bold</v-icon></v-btn>
                            </template>
                            <span>Fechar tela cheia</span>
                          </v-tooltip>
                        </div>
                        <div>
                          <v-tooltip bottom color="primary" class="text-no-wrap">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" @click="sheet= !sheet" elevation="0" class="primary ml-n4 pa-0" x-small style="border-radius: 2px;" height="30px" dark><v-icon small class="mt-1">mdi-filter-menu</v-icon></v-btn>
                            </template>
                            <span>Menu Filtros</span>
                          </v-tooltip>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="11" class="pa-0" style="display: flex; justify-content: center; align-items: center;">
                      <div class="mr-9 text-subtitle-2 font-weight-medium">
                        DATA
                      </div>
                    </v-col>
                  </v-row>
                </th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>MATERIAL BRUTO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>PISOS REVESTIMENTOS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>QUÍMICOS TINTAS ACESSORIOS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>MATERIAL ELÉTRICO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>ILUMINAÇÃO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>HIDRÁULICA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>PORTAS JANELAS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>MATERIAL SANITÁRIO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>LOUÇAS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>MÓVEIS GABINETES</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>FERRAGENS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>FERRAMENTAS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>UTILIDADES DOMÉSTICAS</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>JARDINAGEM LAZER</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>AQUECIMENTO REFRIGERAÇÃO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>MADEIRAS COBERTURA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>FORA LINHA</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>CONCRETO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>ENERGIA SOLAR</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>OUTLET</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap>USO CONSUMO</th>
                <th class="text-center white--text text-subtitle-2 font-weight-medium barra-total-left" nowrap>TOTAL</th>
              </tr>
              </thead>
              <tbody v-if="vendasSecao[0]">
              <tr v-for="(u, i) in vendasSecao" :key="i" class="border-line">
                <td class="px-2 text-center fixed-column" nowrap> {{ u.dtmovimento }}</td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MATERIAL_BRUTO<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MATERIAL_BRUTO | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.PISOS_REVESTIMENTOS<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.PISOS_REVESTIMENTOS | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.QUÍMICOS_TINTAS_ACESSORIOS<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.QUÍMICOS_TINTAS_ACESSORIOS | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MATERIAL_ELETRICO<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MATERIAL_ELETRICO | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.ILUMINACAO<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.ILUMINACAO | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.HIDRAULICA<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.HIDRAULICA | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.PORTAS_JANELAS<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.PORTAS_JANELAS | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MATERIAL_SANITARIO<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MATERIAL_SANITARIO | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.LOUCAS<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.LOUCAS | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MOVEIS_GABINETES<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MOVEIS_GABINETES | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.FERRAGENS<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.FERRAGENS | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.FERRAMENTAS<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.FERRAMENTAS | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.UTILIDADES_DOMESTICAS<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.UTILIDADES_DOMESTICAS | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.JARDINAGEM_LAZER<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.JARDINAGEM_LAZER | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.AQUECIMENTO_REFRIGERACAO<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.AQUECIMENTO_REFRIGERACAO | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.MADEIRAS_COBERTURA<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.MADEIRAS_COBERTURA | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.FORA_LINHA<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.FORA_LINHA | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.CONCRETO<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.CONCRETO | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.ENERGIA_SOLAR<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.ENERGIA_SOLAR | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.OUTLET<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.OUTLET | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="u.secao.USO_CONSUMO<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao.USO_CONSUMO | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left column-totais" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.totaldia | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot v-if="totalizadoresSecao.vendas[0]">
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.variacoes.vendaLiquida[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Liquida</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaLiquida[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaLiquida[0].totalvendaliquida | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Venda Liquida -->
              <tr v-if="totalizadoresSecao.vendas[0]" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Bruta</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendas[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.vendas[0].totalsecaoempresa<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendas[0].totalsecaoempresa | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Venda Bruta -->
              <tr v-if="totalizadoresSecao.perparticSecao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Seção</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perparticSecao[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perparticSecao[0].totalsecaoempresa.toFixed(2) }}%
                </td>
              </tr><!-- % Partic. Seção -->
              <tr v-if="totalizadoresSecao.perparticRegiao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Regional</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perparticRegiao[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perparticRegiao[0].totalpartcregionalgeral.toFixed(2) }}%
                </td>
              </tr><!-- % Partic. Regional -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.variacoes.vendaAnoAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Ano Ant.</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaAnoAnt[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaAnoAnt[0].totalvendalastyear | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Venda Liq. Ano Ant. -->
              <tr v-if="totalizadoresSecao.variacoes.variacaoNominal[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Nominal</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacaoNominal[0].secao[s])">
                    {{ totalizadoresSecao.variacoes.variacaoNominal[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacaoNominal[0].variacaonominal)">
                    {{ totalizadoresSecao.variacoes.variacaoNominal[0].variacaonominal.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- % Variação Nominal -->
              <tr v-if="totalizadoresSecao.variacoes.vendaMesAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Mês Ant.</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaMesAnt[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.variacoes.vendaMesAnt[0].totalvendalastmonth | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Venda Liq. Mês Ant. -->
              <tr v-if="totalizadoresSecao.variacoes.variacao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Mês Ant.</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacao[0].secao[s])">
                    {{ totalizadoresSecao.variacoes.variacao[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacao[0].variacao)">
                    {{ totalizadoresSecao.variacoes.variacao[0].variacao.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- % Variação Mês Ant. -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.vendasObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Vendas de Obsoletos (R$)
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="red"
                          class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Apoio a decisão</span>
                  </v-tooltip>
                </td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendasObsoletos[0].secoes[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendasObsoletos[0].total_venda_obsoleto | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Vendas de Obsoletos (R$) -->
              <tr v-if="totalizadoresSecao.vendasObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Margem de Obsoletos (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.vendasObsoletos[0].margens[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.vendasObsoletos[0].margem_venda_obsoleto.toFixed(2) }}%
                </td>
              </tr><!--  Margem de Obsoletos (%) -->
              <tr v-if="totalizadoresSecao.estoqueObsoletos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque de Obsoletos</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.estoqueObsoletos[0].secoes[s] | formataDinheiro }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.estoqueObsoletos[0].total_estoque | formataDinheiro }}
                </td>
              </tr>   <!--  Estoque de Obsoletos -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.devolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Devoluções (R$)</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.devolucao[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.devolucao[0].totaldevolucao | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Devoluções (R$) -->
              <tr v-if="totalizadoresSecao.perDevolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Total Devoluções (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perDevolucao[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perDevolucao[0].totaldevolucao.toFixed(2) }}%
                </td>
              </tr><!--  Total Devoluções (%) -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.descontos.desconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (R$)</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.descontos.desconto[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.descontos.desconto[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="vendasSecao.total<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.descontos.desconto[0].totaldesconto | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Desconto -->
              <tr v-if="totalizadoresSecao.descontos.perdesconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.descontos.perdesconto[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.descontos.perdesconto[0].totaldesconto.toFixed(2) }}%
                </td>
              </tr><!-- Total de Descontos (%) -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.margemMeta[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.margemMeta[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.margemMeta[0].totalmargemmetafilial.toFixed(2) }}%
                </td>
              </tr>    <!--  Margem Meta -->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr v-if="totalizadoresSecao.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Realizada Acum.</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margem[0].secao[s])">
                    {{ totalizadoresSecao.margem[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margem[0].totalmargemgeral)">
                    {{ totalizadoresSecao.margem[0].totalmargemgeral.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- Margem Realizada Acum. -->
              <tr v-if="totalizadoresSecao.margemAnoAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Ano Anterior</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margemAnoAnt[0].secao[s])">
                    {{ totalizadoresSecao.margemAnoAnt[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margemAnoAnt[0].totalmargemlastyeargeral)">
                    {{ totalizadoresSecao.margemAnoAnt[0].totalmargemlastyeargeral.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- Margem Ano Anterior -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Entrada Ajuste de Estoque</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.entradasSaidasAvulsa[0].lojasent[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].lojasent[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalentrada<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].totalentrada | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Entrada Ajuste de Estoque -->
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saída Ajuste de Estoque</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.entradasSaidasAvulsa[0].lojassaid[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].lojassaid[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalsaida<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].totalsaida | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Saída Ajuste de Estoque -->
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saldo de Ajustes</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.entradasSaidasAvulsa[0].saldo[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].saldo[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalsaldo<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.entradasSaidasAvulsa[0].totalsaldo | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Saldo de Ajustes -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.estoqueAnoAnt[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Estoque Ano Anterior</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.estoqueAnoAnt[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueAnoAnt[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.estoqueAnoAnt[0].totalestoqueinicial<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueAnoAnt[0].totalestoqueinicial | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Estoque Ano Anterior -->
              <tr v-if="totalizadoresSecao.diasEstoqueFuturo[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Futuro</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.diasEstoqueFuturo[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.diasEstoqueFuturo[0].totaldiasestoquefuturofilial }}
                </td>
              </tr><!--  Dias de Estoque - Futuro - Em desenvolvimento ...-->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Dias de Estoque - Futuro</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr v-if="totalizadoresSecao.diasEstoqueReal[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Real</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.diasEstoqueReal[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.diasEstoqueReal[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.diasEstoqueReal[0].totaldiasestoquerealfilial<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.diasEstoqueReal[0].totaldiasestoquerealfilial | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!--  Dias de Estoque - Real - Em desenvolvimento ...-->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Dias de Estoque - Real</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr v-if="totalizadoresSecao.estoqueInicial[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Inicial</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.estoqueInicial[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueInicial[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.estoqueInicial[0].totalestoqueinicial<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueInicial[0].totalestoqueinicial | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Estoque Inicial -->
              <tr v-if="totalizadoresSecao.movimentacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Movimentações</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.movimentacoes[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.movimentacoes[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.movimentacoes[0].totalmovimentacoes<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.movimentacoes[0].totalmovimentacoes | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Movimentacoes -->
              <tr v-if="totalizadoresSecao.estoqueFinal[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Final</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.estoqueFinal[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueFinal[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.estoqueFinal[0].totalestoquefinal<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueFinal[0].totalestoquefinal | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Estoque Final -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Saldo Pedidos de Compra
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="primary"
                          class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Custo Última Compra</span>
                  </v-tooltip>
                </td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.pedidos[0].secao[s]<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.pedidos[0].secao[s] | formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;" :style="totalizadoresSecao.pedidos[0].totalpedido<0?'color:red;':''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.pedidos[0].totalpedido | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>   <!--  Saldo Pedidos -->
              <tr v-if="totalizadoresSecao.itensAtivos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.itensAtivos[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.itensAtivos[0].totalitensativosfilial}}
                </td>
              </tr>   <!--  Total de Sku's em Estoque -->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
              <tr v-if="totalizadoresSecao.itensEncalhados[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.itensEncalhados[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.itensEncalhados[0].totalitensencalhadosfilial.toFixed(2) }}%
                </td>
              </tr>   <!--  Sku's Encalhados -->
<!--              <tr v-else-if="!carregandoTotais" class="border-line-foot font-weight-medium">-->
<!--                <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>-->
<!--                <td colspan="100%" class="text-caption font-italic">Em desenvolvimento...</td>-->
<!--              </tr>   &lt;!&ndash;  remover depois &ndash;&gt;-->
<!--              <tr class="barra-total-top">-->
<!--                <td colspan="100%"></td>-->
<!--              </tr>-->
              <tr v-if="totalizadoresSecao.rupturaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva A</td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1001, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MATERIAL_BRUTO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1002, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.PISOS_REVESTIMENTOS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1003, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.QUÍMICOS_TINTAS_ACESSORIOS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1004, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MATERIAL_ELETRICO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1005, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.ILUMINACAO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1006, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.HIDRAULICA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1007, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.PORTAS_JANELAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1008, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MATERIAL_SANITARIO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1009, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.LOUCAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1010, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MOVEIS_GABINETES }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1011, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.FERRAGENS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1012, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.FERRAMENTAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1013, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.UTILIDADES_DOMESTICAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1014, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.JARDINAGEM_LAZER }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1015, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.AQUECIMENTO_REFRIGERACAO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1016, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.MADEIRAS_COBERTURA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1018, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.FORA_LINHA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1020, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.CONCRETO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1021, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.ENERGIA_SOLAR }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1019, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.OUTLET }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1017, 'RA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao.USO_CONSUMO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaA[0].totalrupturaafilial }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva A-->
              <tr v-if="totalizadoresSecao.curvaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A</td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1001, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MATERIAL_BRUTO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1002, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.PISOS_REVESTIMENTOS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1003, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.QUÍMICOS_TINTAS_ACESSORIOS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1004, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MATERIAL_ELETRICO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1005, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.ILUMINACAO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1006, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.HIDRAULICA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1007, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.PORTAS_JANELAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1008, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MATERIAL_SANITARIO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1009, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.LOUCAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1010, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MOVEIS_GABINETES.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1011, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.FERRAGENS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1012, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.FERRAMENTAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1013, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.UTILIDADES_DOMESTICAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1014, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.JARDINAGEM_LAZER.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1015, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.AQUECIMENTO_REFRIGERACAO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1016, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.MADEIRAS_COBERTURA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1018, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.FORA_LINHA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1020, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.CONCRETO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1021, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.ENERGIA_SOLAR.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1019, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.OUTLET.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1017, 'CA', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao.USO_CONSUMO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.curvaA[0].curvaa.toFixed(0) }}
                </td>
              </tr><!-- Total de Sku's da Curva A-->
              <tr v-if="totalizadoresSecao.perCurvaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva A</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perCurvaA[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.perCurvaA[0].TotalPerccurvaa.toFixed(2) }}%
                </td>
              </tr><!-- % Ruptura da Curva A-->
              <tr v-if="totalizadoresSecao.rupturaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva B/C</td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1001, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MATERIAL_BRUTO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1002, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.PISOS_REVESTIMENTOS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1003, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.QUÍMICOS_TINTAS_ACESSORIOS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1004, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MATERIAL_ELETRICO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1005, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.ILUMINACAO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1006, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.HIDRAULICA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1007, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.PORTAS_JANELAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1008, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MATERIAL_SANITARIO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1009, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.LOUCAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1010, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MOVEIS_GABINETES }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1011, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.FERRAGENS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1012, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.FERRAMENTAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1013, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.UTILIDADES_DOMESTICAS }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1014, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.JARDINAGEM_LAZER }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1015, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.AQUECIMENTO_REFRIGERACAO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1016, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.MADEIRAS_COBERTURA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1018, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.FORA_LINHA }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1020, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.CONCRETO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1021, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.ENERGIA_SOLAR }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1019, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.OUTLET }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1017, 'RB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao.USO_CONSUMO }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaBC[0].totalrupturaBcfilial }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva B/C-->
              <tr v-if="totalizadoresSecao.curvaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva B/C</td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1001, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MATERIAL_BRUTO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1002, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.PISOS_REVESTIMENTOS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1003, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.QUÍMICOS_TINTAS_ACESSORIOS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1004, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MATERIAL_ELETRICO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1005, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.ILUMINACAO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1006, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.HIDRAULICA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1007, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.PORTAS_JANELAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1008, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MATERIAL_SANITARIO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1009, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.LOUCAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1010, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MOVEIS_GABINETES.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1011, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.FERRAGENS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1012, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.FERRAMENTAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1013, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.UTILIDADES_DOMESTICAS.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1014, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.JARDINAGEM_LAZER.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1015, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.AQUECIMENTO_REFRIGERACAO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1016, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.MADEIRAS_COBERTURA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1018, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.FORA_LINHA.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1020, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.CONCRETO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1021, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.ENERGIA_SOLAR.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1019, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.OUTLET.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(1017, 'CB', '')" class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao.USO_CONSUMO.toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.curvaBC[0].Totalcurvabc.toFixed(0) }}
                </td>
              </tr><!-- Total de Sku's da Curva B/C-->
              <tr v-if="totalizadoresSecao.perCurvaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva B/C</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perCurvaBC[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perCurvaBC[0].Totalcurvabc.toFixed(2) }}%
                </td>
              </tr><!-- % Ruptura da Curva B/C-->
              <tr v-if="totalizadoresSecao.rupturaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva (A,B,C)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.rupturaGeral[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaGeral[0].totalruptura }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva (A,B,C)-->
              <tr v-if="totalizadoresSecao.curvaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% de Ruptura Total (A,B,C)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.curvaGeral[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.curvaGeral[0].totalcurvafilialloja.toFixed(2) }}%
                </td>
              </tr><!-- % de Ruptura Total (A,B,C)-->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              </tfoot>
            </v-simple-table>
            <v-alert class="error mb-0" v-else-if="!carregando">Nenhum registro encontrado.</v-alert>
            <v-skeleton-loader light v-if="carregando || carregandoTotais || carregandoTotais2" type="table-tfoot" />
            <v-skeleton-loader light v-if="carregando || carregandoTotais || carregandoTotais2" type="table-tfoot" />
            <v-skeleton-loader light v-if="carregando || carregandoTotais || carregandoTotais2" type="table-tfoot" />
          </v-card-text>
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetalheRuptura" scrollable width="1300px">
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          <span v-if="tipoRuptura=='RA'">Sku's em Ruptura da Curva A ({{ !busca.idempresa ? nomeEmpresaDialog: empresasTitulo.filter((v) => v.idfilial == busca.idempresa).map((u) => u.filial)[0] }})</span>
          <span v-else-if="tipoRuptura=='CA'">Total de Sku's da Curva A ({{ !busca.idempresa ? nomeEmpresaDialog: empresasTitulo.filter((v) => v.idfilial == busca.idempresa).map((u) => u.filial)[0] }})</span>
          <span v-else-if="tipoRuptura=='RB'">Total de Sku's da Curva B/C ({{ !busca.idempresa ? nomeEmpresaDialog: empresasTitulo.filter((v) => v.idfilial == busca.idempresa).map((u) => u.filial)[0] }})</span>
          <span v-else-if="tipoRuptura=='CB'">% Ruptura da Curva B/C ({{!busca.idempresa ? nomeEmpresaDialog: empresasTitulo.filter((v) => v.idfilial == busca.idempresa).map((u) => u.filial)[0] }})</span>
          <v-spacer/>
          <v-btn height="30" text x-small @click="dialogDetalheRuptura=false" dark>
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
              :height="detalheRuptura?(detalheRuptura.length>14?'70vh':'100%'):'100%'"
              fixed-header
              :items-per-page="30"
              mobile-breakpoint="0"
              :headers="[
                  { text: 'Cód. Produto', value: 'idsubproduto', class:'pr-0 text-trucate' },
                  { text: 'Descrição Produto', value: 'produto', class:'pr-0 text-no-wrap'  },
                  { text: 'Qtd. Estoque', value: 'qtdestoque', align:'center ml-4', class:'pr-0 text-truncate'},
                  { text: 'Cód. Fornecedor', value: 'codigofornecedor', class:'pr-0 text-no-wrap'  },
                  { text: 'Descrição Fornecedor', value: 'fornecedor', class:'pr-0 text-no-wrap'  },
                  { text: 'Compradores', value: 'comprador', class:'text-no-wrap' },
              ]"
              :items="detalheRuptura?detalheRuptura.map((u) => {
                  return {
                      idsubproduto: u.idsubproduto,
                      produto: u.produto,
                      qtdestoque: u.qtdestoque,
                      codigofornecedor: u.codigofornecedor,
                      fornecedor: u.fornecedor,
                      comprador: u.comprador,
                  };
              }):[]"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.idsubproduto }}</td>
                <td class="text-left" nowrap> {{ item.produto }} </td>
                <td class="text-center" nowrap> {{ item.qtdestoque }}</td>
                <td class="text-left" nowrap> {{ item.codigofornecedor }}</td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.fornecedor | formataTextoCurto}}</span>
                    </template>
                    <span>{{ item.fornecedor }}</span>
                  </v-tooltip>
                </td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <span v-on="on">{{ item.comprador | formataTextoPedido }}</span>
                    </template>
                    <span>{{ item.comprador }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetalhePedidos" scrollable width="1300px">
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          <span>Pedidos de compra pendentes - ({{ nomeEmpresaDialog }})</span>
          <v-spacer/>
          <v-btn height="30" text x-small @click="dialogDetalhePedidos=false" dark>
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
              v-if="detalhePedidos[0]"
              :headers="[
                  {text: 'N° pedido', value: 'idpedido',class:'text-no-wrap' },
                  {text: 'Data', value: 'dtpedido', class:'text-no-wrap'  },
                  {text: 'Código', value: 'idclifor', class:'text-no-wrap'  },
                  {text: 'Fornecedor', value: 'cliente', class:'text-no-wrap'  },
                  {text: 'Usuário', value: 'usuario', class:'text-no-wrap'  },
                  {text: 'Total Solicitado', value: 'qtdsolicitada', class:'text-no-wrap'  },
                  {text: 'Total Atendido', value: 'qtdatendida', class:'text-no-wrap'  },
                  {text: 'Total Pendente', value: 'qtdpendente', class:'text-no-wrap'  },
                  {text: 'Total Valor', value: 'Valor', class:'text-no-wrap'  },
              ]"
              :items="detalhePedidos"
              :expanded.sync="expanded"
              expand-icon="mdi-menu-down"
              item-key="idpedido"
              class="elevation-0 table_clicavel"
              :items-per-page="50"
              @click:row="handleRowClick"
              single-expand
              :item-class="rowClass"
              mobile-breakpoint="0"
              :footer-props="{
                      showFirstLastPage: true,
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      itemsPerPageText: 'Pedidos por página',
                      'items-per-page-options': [30, 50, 100, 500],
                      disableItemsPerPage: false,
                   }"
          >
            <template v-slot:item.dtpedido="{ item }">
              {{  item.dtpedido | formatDate }}
            </template>

            <template v-slot:item.cliente="{ item }">
              <span class="text-no-wrap">{{  item.cliente | formataTextoPedido }}</span>
            </template>

            <template v-slot:item.qtdsolicitada="{ item }">
              {{  item.qtdsolicitada | formataQtdProduto }}
            </template>

            <template v-slot:item.qtdatendida="{ item }">
              {{  item.qtdatendida | formataQtdProduto }}
            </template>

            <template v-slot:item.qtdpendente="{ item }">
              {{  item.qtdpendente | formataQtdProduto }}
            </template>

            <template v-slot:item.Valor="{ item }">
              {{  item.Valor | formataDinheiro }}
            </template>

            <template v-slot:expanded-item="{ }">
              <td colspan="10" class="px-0 primary">
                <v-simple-table class="table">
                  <thead class="header">
                  <tr class="cursor-normal">
                    <th nowrap>Cód. produto</th>
                    <th nowrap>Descrição produto</th>
                    <th nowrap>Valor</th>
                    <th nowrap>Qtd. solicitada</th>
                    <th nowrap>Qtd. atendida</th>
                    <th nowrap>Qtd. pendente</th>
                  </tr>
                  </thead>
                  <tbody class="body text-capitalize" >
                  <tr v-if="carregando2"><td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td></tr>
                  <tr v-for="(item, index) in detalheProdutos" :key="index" class="cursor-normal" v-else>
                    <td nowrap>{{ item.idsubproduto }}</td>
                    <td nowrap>{{ item.produto | formataTextoPedidoConcreto}}</td>
                    <td nowrap>{{ item.Valor | formataDinheiro }}</td>
                    <td nowrap>{{ item.qtdsolicitada | formataQtdProduto  }}</td>
                    <td nowrap>{{ item.qtdatendida | formataQtdProduto  }}</td>
                    <td nowrap>{{ item.qtdpendente | formataQtdProduto }}</td>
                  </tr>
                  </tbody>
                  <tfoot class="foot text-capitalize" v-if="detalheProdutos[0] && !carregando2">
                  <tr class="cursor-normal">
                    <td nowrap></td>
                    <td nowrap></td>
                    <td nowrap>{{ detalheProdutos.map(v => v.Valor).reduce((a,v) => a+v) | formataDinheiro}}</td>
                    <td nowrap>{{ (detalheProdutos.map(v => v.qtdsolicitada).reduce((a,v) => a+v)) | formataQtdProduto }}</td>
                    <td nowrap>{{ (detalheProdutos.map(v => v.qtdatendida).reduce((a,v) => a+v)) | formataQtdProduto }}</td>
                    <td nowrap>{{ (detalheProdutos.map(v => v.qtdpendente).reduce((a,v) => a+v)) | formataQtdProduto }}</td>
                  </tr>
                  </tfoot>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="carregandoTotais || carregandoTotais2 || carregandoTotais3" v-model="dialog" persistent overlay-opacity="0.8" width="300px">
      <v-card color="primary" dark>
        <v-card-text class="pa-5">
          <span v-if="carregandoTotais">Aguarde, vendas sendo carregadas.</span>
          <span v-else>Aguarde, totais sendo carregados.</span>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";

export default {
	name: "OnePage",
	mixins: [mixinFilial],
	components: {},
	data: () => ({
    carregando2:false,
    expanded: [],
    sheet: false,
    dialog:false,
    dataUltAtualizacao: '',
    carregandoTotais: true,
    carregandoTotais2: false,
    carregandoTotais3: true,
    carregando: true,
    filtroRegional: 0,
    periodos: [],
    regionais: [],
    secoes: [],
    vendasSecao: [],
    vendasLojas: [],
    totalizadores: {
      vendas:{
        perclojas:[],
        percpartregionais:[],
        totaloja:[]
      },
      vendasObsoletos: [],
      metasMes:[],
      metasDia:[],
      metasVariacao:[],
      variacoes:[],
      devolucao: [],
      perDevolucao:[],
      desconto: [],
      metasDesconto:[],
      metasPerDesconto:[],
      margem: [],
      margemMeta:[],
      diasEstoqueFuturo:[],
      diasEstoqueReal:[],
      estoque:[],
      estoqueAnoAnt:[],
      entradasSaidasAvulsa:[],
      pedidos: [],
      itensAtivos:[],
      itensEncalhados:[],
      estoqueObsoletos:[],
      ruptura:[],
      rupturaA:[],
      rupturaBC:[],
      rupturaGeral:[],
      curva:[],
      curvaGeral:[]
    },
    totalizadoresSecao: {
      vendas:[],
      vendasObsoletos: [],
      perparticSecao:[],
      perparticRegiao:[],
      variacoes:{
        vendaAnoAnt:[],
        variacaoNominal:[],
        vendaMesAnt:[],
        variacao:[],
        vendaLiquida:[]
      },
      devolucao:[],
      perDevolucao:[],
      descontos: {
        desconto:[],
        perdesconto:[]
      },
      margem:[],
      margemMeta:[],
      diasEstoqueFuturo:[],
      diasEstoqueReal:[],
      margemAnoAnt:[],
      estoqueAnoAnt:[],
      entradasSaidasAvulsa:[],
      estoqueInicial: [],
      movimentacoes: [],
      estoqueFinal: [],
      pedidos:[],
      itensAtivos:[],
      itensEncalhados:[],
      estoqueObsoletos:[],
      rupturaA:[],
      rupturaBC:[],
      rupturaGeral:[],
      curvaA:[],
      curvaBC:[],
      perCurvaA:[],
      perCurvaBC:[],
      curvaGeral:[],
    },
    stringDeEmpresas: '',
		busca: { idempresa: null, idsecao: null, periodo: { data: null } },
    dialogDetalheRuptura: false,
    dialogDetalhePedidos: false,
    detalheRuptura:[],
    detalhePedidos:[],
    detalheProdutos:[],
    nomeEmpresaDialog:'',
    tipoRuptura:'',
    empresasTitulo: [
      { "filial": "Ivaiporã", "idfilial": 1 } ,
      { "filial": "Pitanga", "idfilial": 2 },
      { "filial": "São João do Ivaí", "idfilial": 3 },
      { "filial": "Castelo", "idfilial": 4 },
      { "filial": "Candido", "idfilial": 5 },
      { "filial": "Apucarana", "idfilial": 7 },
      { "filial": "Jardim Alegre", "idfilial": 8 },
      { "filial": "Manoel Ribas", "idfilial": 9 },
      { "filial": "Faxinal", "idfilial": 10 },
      { "filial": "Campo Mourão", "idfilial": 11 },
      { "filial": "Cianorte", "idfilial": 14 },
      { "filial": "Paranavaí", "idfilial": 15 },
      { "filial": "Telêmaco", "idfilial": 16 },
      { "filial": "Roncador", "idfilial": 17 },
      { "filial": "Guarapuava", "idfilial": 18 },
      { "filial": "Palmital", "idfilial": 19 },
      { "filial": "Ibiporã", "idfilial": 20 },
      { "filial": "Turvo", "idfilial": 21 },
      { "filial": "São Mateus", "idfilial": 23 },
      { "filial": "São João do Triunfo", "idfilial": 24 },
      { "filial": "Pontal do Paraná", "idfilial": 26 },
      { "filial": "Mandaguari", "idfilial": 27 },
      { "filial": "Astorga", "idfilial": 28 },
      { "filial": "Cambé", "idfilial": 30 },
      { "filial": "Reserva", "idfilial": 31 },
      { "filial": "Rolândia", "idfilial": 35 },
      { "filial": "Maringá", "idfilial": 36 },
      { "filial": "Laranjeiras", "idfilial": 38 },
      { "filial": "E-commerce - CD", "idfilial": 39 }
    ],
    lojas: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 26, 27, 28, 30, 31, 35, 36, 38, 39],
    secoesArray:[
      'MATERIAL_BRUTO',
      'PISOS_REVESTIMENTOS',
      'QUÍMICOS_TINTAS_ACESSORIOS',
      'MATERIAL_ELETRICO',
      'ILUMINACAO',
      'HIDRAULICA',
      'PORTAS_JANELAS',
      'MATERIAL_SANITARIO',
      'LOUCAS',
      'MOVEIS_GABINETES',
      'FERRAGENS',
      'FERRAMENTAS',
      'UTILIDADES_DOMESTICAS',
      'JARDINAGEM_LAZER',
      'AQUECIMENTO_REFRIGERACAO',
      'MADEIRAS_COBERTURA',
      'FORA_LINHA',
      'CONCRETO',
      'ENERGIA_SOLAR',
      'OUTLET',
      'USO_CONSUMO'],
    lojasFiltradas: [],
  }),
	computed: {
		...mapState(["backendUrl", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
            v.idfilial != 13 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37 &&
            v.idfilial != 40
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
    handleRowClick(item) {
      this.carregando2=true;
      if (this.expanded.includes(item)) {
        // Se o item já estiver expandido, remove-o da lista de expandidos
        this.listarProdutos(item);
        this.expanded = this.expanded.filter(i => i !== item);
      } else {
        // Caso contrário, define a lista de expandidos para conter apenas o item atual
        this.listarProdutos(item);
        this.expanded = [item];
      }
    },

    listarProdutos(item){
      this.detalheProdutos=[];
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/pedidos/notas/itens/listar`, {
            idpedido: item.idpedido,
          })
          .then((res) => {
            this.detalheProdutos = res.data;
            this.carregando2 = false;
          })
          .catch(() => {
            this.carregando2 = false;
          });
    },

    carrega(){
        setTimeout(() => {
          this.listarTotaisMetasDesconto();
          this.listarTotaisMetasPerDesconto(); //%
          this.listarTotaisMetasMes();
          this.listarTotaisMetasDia();
          this.listarTotaisMetasVariacao(); //%
          this.listarTotaisVariacoes();
          this.listarDataAtualizacao();
        }, 1000);
        setTimeout(() => {
          this.carregandoTotais= false;
          this.carregandoTotais2= true;
          this.listarTotaisDevolucao(); // R$
          this.listarTotaisPerDevolucao(); //%
          this.listarTotaisDesconto(); // R$
          this.listarTotaisMargem();  // % mes passado e ano passado
          this.listarTotaisMargemMeta(); //%
          // --this.listarTotaisDiasEstoqueFuturo();
          // --this.listarTotaisDiasEstoqueReal();
          this.listarTotaisObsoletos();
          this.listarTotaisEstoque();
          this.listarTotaisEntradasSaidasAvulsa();
          this.listarTotaisEstoqueAnoAnt();
          this.listarTotaisPedidos();
          this.listarTotaisItensAtivos();
          this.listarTotaisEstoqueObsoletos();
          // --this.listarTotaisItensEncalhados();
          this.listarTotaisRupturaA();
          this.listarTotaisRupturaBC();
          this.listarTotaisGeralRuptura();
          this.listarTotaisCurva();
          this.listarTotaisGeralCurva();
        }, 2000);
      setTimeout(() => {
        this.carregandoTotais3 =false;
      }, 3000);
    },
    carrega2(){
      setTimeout(() => {
        this.listarTotaisVendasLiquidaEmpresa();
        this.listarTotaisParticVendasEmpresa();
        this.listarTotaisParticRegiaoVendasEmpresa();
        this.listarTotaisVendasEmpresaLastyear();
        this.listarTotaisVendasEmpresaNominal();
        this.listarTotaisVendasEmpresaLastmonth();
        this.listarTotaisVendasEmpresaVariacao();
        this.listarDataAtualizacao();
      }, 1000);
      setTimeout(() => {
        this.carregandoTotais= false;
        this.carregandoTotais2= true;
        this.listarTotaisEmpresaDevolucao();
        this.listarTotaisEmpresaPerDevolucao();
        this.listarTotaisObsoletosEmpresa();
        this.listarTotaisEmpresaDesconto();
        this.listarTotaisEmpresaDescontoConcedido();
        this.listarTotaisEmpresaMargem();
        // this.listarTotaisEmpresaMargemMeta();
        // this.listarTotaisEmpresaDiasEstoqueFuturo();
        // this.listarTotaisEmpresaDiasEstoqueReal();
        this.listarTotaisEmpresaMargemLastyear();
        this.listarTotaisEmpresaEstoqueAnoAnt();
        this.listarTotaisEmpresaEntradasSaidasAvulsa();
        this.listarTotaisEmpresaEstoqueInicial();
        this.listarTotaisEmpresaMovimentacoes();
        this.listarTotaisEmpresaEstoqueFinal();
        this.listarTotaisPedidosEmpresa();
        this.listarTotaisItensAtivosEmpresa();
        this.listarTotaisEstoqueObsoletosEmpresa();
        // this.listarTotaisItensEncalhadosEmpresa();
      }, 2000);
      setTimeout(() => {
        this.listarTotaisRupturaAEmpresa();
        this.listarTotaisRupturaBCEmpresa();
        this.listarTotaisRupturaGeralEmpresa();
        this.listarTotaisCurvaAEmpresa();
        this.listarTotaisCurvaBCEmpresa();
        this.listarTotaisPerCurvaAEmpresa();
        this.listarTotaisPerCurvaBCEmpresa();
        this.listarTotaisCurvaGeralEmpresa();
      }, 3000);
      setTimeout(() => {
        this.carregandoTotais3 =false;
      }, 4000);
    },

    coresPerDesconto(nomeLoja){
      let meta = (this.totalizadores.metasPerDesconto[0].metadesconto[nomeLoja])
      let concedido = (this.totalizadores.desconto[0].descontoconcedido[nomeLoja] )

      if (meta  > concedido ) {
        return 'blue darken-2 white--text'
      } else if (meta  == concedido) {
        return 'yellow accent-2'
      } else {
        return 'red white--text'
      }
    },

    limpaValores(){
      this.vendasLojas=[];
      this.vendasSecao=[];
      this.totalizadores = {
        vendas:{
          perclojas:[],
          percpartregionais:[],
          totaloja:[]
        },
        vendasObsoletos: [],
        metasMes:[],
        metasDia:[],
        metasVariacao:[],
        variacoes:[],
        devolucao: [],
        perDevolucao:[],
        desconto: [],
        metasDesconto:[],
        metasPerDesconto:[],
        margem: [],
        margemMeta:[],
        diasEstoqueFuturo:[],
        diasEstoqueReal:[],
        estoque:[],
        estoqueAnoAnt:[],
        entradasSaidasAvulsa:[],
        pedidos: [],
        itensAtivos:[],
        itensEncalhados:[],
        estoqueObsoletos:[],
        ruptura:[],
        rupturaA:[],
        rupturaBC:[],
        rupturaGeral:[],
        curva:[],
        curvaGeral:[]
      };
      this.totalizadoresSecao = {
        vendas:[],
        vendasObsoletos: [],
        perparticSecao:[],
        perparticRegiao:[],
        variacoes:{
          vendaAnoAnt:[],
          variacaoNominal:[],
          vendaMesAnt:[],
          variacao:[],
          vendaLiquida:[]
        },
        devolucao:[],
        perDevolucao:[],
        descontos: {
          desconto:[],
          perdesconto:[]
        },
        margem:[],
        margemMeta:[],
        diasEstoqueFuturo:[],
        diasEstoqueReal:[],
        margemAnoAnt:[],
        estoqueAnoAnt:[],
        entradasSaidasAvulsa:[],
        estoqueInicial: [],
        movimentacoes: [],
        estoqueFinal: [],
        pedidos:[],
        itensAtivos:[],
        itensEncalhados:[],
        estoqueObsoletos:[],
        rupturaA:[],
        rupturaBC:[],
        rupturaGeral:[],
        curvaA:[],
        curvaBC:[],
        perCurvaA:[],
        perCurvaBC:[],
        curvaGeral:[],
      };
    },

    coresMargem(per){
        if(per <= 18){
          return 'red white--text'
        } else if(per < 21){
          return 'orange darken-1 white--text'
        } else if(per <= 24){
          return 'yellow accent-2'
        } else if(per <= 26){
          return 'light green white--text'
        } else if(per > 26){
          return 'blue darken-2 white--text'
        }
    },

    coresVarMeta(per){
      if(per < 70){
        return 'red white--text'
      } else if(per < 100){
        return 'yellow accent-2'
      } else if(per > 100){
        return 'blue darken-2 white--text'
      }
    },

    coresVarariacoes(per){
      if(per < 0){
        return 'red white--text'
      }
      else if (per == 0){
        return 'yellow accent-2'
      } else {
        return 'blue darken-2 white--text'
      }
    },

    validarRegiao(numero) {
      const numeroDigitado = parseInt(numero)
      let norte = (this.regionais[0].listaempresa.split(',').map(num => parseInt(num))).includes(numeroDigitado)
      let sul = (this.regionais[1].listaempresa.split(',').map(num => parseInt(num))).includes(numeroDigitado)
      let centro = (this.regionais[2].listaempresa.split(',').map(num => parseInt(num))).includes(numeroDigitado)
      if (norte){
        return this.totalizadores.percpartregionais[0].percregional
      } else if (sul) {
        return this.totalizadores.percpartregionais[1].percregional
      } else if (centro) {
        return this.totalizadores.percpartregionais[2].percregional
      }
      return 0;
    },
    validarNumero(numero) {
      if (this.filtroRegional !=0){
        const numeroDigitado = parseInt(numero)
        const numeros = this.stringDeEmpresas.split(',').map(num => parseInt(num))
        let valida = numeros.includes(numeroDigitado)
        return valida;
      }
      return true;
    },

    rowClass(item) {
      return this.expanded.includes(item) ? 'primary white--text c' : '';
    },
    // Filtros
		listarPeriodos() {
			return axios
				.post(`${this.backendUrl}dashboard/categoria/periodo/listar`)
				.then((res) => {
          if (this.periodos.length > 0 ) {
            this.periodos = res.data;
          } else {
            this.periodos = res.data;
            this.busca.periodo = res.data[0];
          }
				});
		},
		listarSecoes() {
			return axios
				.post(`${this.backendUrl}dashboard/categoria/secao/listar`)
				.then((res) => {
          this.secoes = res.data.map((s) => {
            return {
              idsecao: s.idsecao,
              secao: s.secao,
              descr: `${s.idsecao} - ${s.secao}`,
            };
          }).sort((a, b) => a.idsecao - b.idsecao);
          // this.busca.idsecao = this.secoes[2].idsecao;
				});
		},
    listarRegioes() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/regional/listar`)
          .then((res) => {
            this.regionais = res.data;
          });
    },
    listarVendas() {
      this.lojasFiltradas = this.lojas;
      this.carregandoTotais= true;
      this.carregando = true;
      this.carregandoTotais3 = true;
      this.limpaValores();
      if (!this.busca.idempresa){
        this.listarTotaisVendas();
        this.carrega();
        return axios
            .post(`${this.backendUrl}dashboard/categoria/venda/listar`, {
              idsecao: this.busca.idsecao || null,
              idregional: this.busca.idregional,
              periodo: this.busca.periodo.data,
            })
            .then((res) => {
              if (res.data != null) {
                this.filtroRegional = this.busca.idregional || 0;
                this.vendasLojas = res.data;
                if (this.filtroRegional != 0) {
                  this.stringDeEmpresas = this.regionais.filter((v) => {
                    return v.idregional == this.filtroRegional
                  })[0].listaempresa;
                  this.lojasFiltradas = this.stringDeEmpresas.split(","); // ["hello", "world"]
                }
                this.carregando = false;
              } else {
                this.carrega();
                this.vendasLojas = [];
                this.carregando = false;
              }
            })
            .catch(() => {
              this.carregando = false;
            });
      } else {
        this.listarTotaisVendasEmpresa();
        this.carrega2();
        return axios
            .post(`${this.backendUrl}dashboard/categoria/venda/empresa/listar`, {
              idsecao: this.busca.idsecao || null,
              idempresa: this.busca.idempresa,
              periodo: this.busca.periodo.data,
            })
            .then((res) => {
              if (res.data != null) {
                this.vendasSecao = res.data.VendaEmpresa;
                this.carregando = false;
              } else {
                this.vendasSecao = [];
                this.carregando = false;
              }
            })
            .catch(() => {
              this.carregando = false;
            });
      }
    },

    // Detalhamento
    detalharRupturaEmpresa(p_idsecao, p_tipo, p_nomeempresa){
      this.nomeEmpresaDialog = p_nomeempresa;
      this.tipoRuptura = p_tipo;
      this.dialogDetalheRuptura = true;
      this.detalheRuptura=[];
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/ruptura/itens/filial/listar`, {
            idempresa: this.busca.idempresa,
            tipo: p_tipo,
            idsecao: p_idsecao,

          })
          .then((res) => {
            this.detalheRuptura = res.data;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    // Detalhamento
    detalharRuptura(p_idempresa, p_tipo, p_nomeempresa){
      this.nomeEmpresaDialog = p_nomeempresa;
      this.tipoRuptura = p_tipo;
      this.dialogDetalheRuptura = true;
      this.detalheRuptura=[];
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/rupturaa/itens/listar`, {
            idempresa: p_idempresa,
            tipo: p_tipo,
            idsecao: this.busca.idsecao || null,
          })
          .then((res) => {
            this.detalheRuptura = res.data;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },

    detalharPedidos(p_idempresa, p_nomeempresa){
      this.detalhePedidos=[];
      this.nomeEmpresaDialog = p_nomeempresa;
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/pedidos/notas/listar`, {
            idempresa: p_idempresa,
            idsecao: this.busca.idsecao || null,
          })
          .then((res) => {
            this.detalhePedidos = res.data;
            this.dialogDetalhePedidos = true;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },

    // Totais lojas
    listarTotaisVendas() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/totalizadores/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.vendas = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisObsoletos() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/obsoleto/listar`, {
            periodo: this.busca.periodo.data,
            idregional: this.busca.idregional,
            idsecao: this.busca.idsecao || null,
          })
          .then((res) => {
            this.totalizadores.vendasObsoletos = res.data.principal;
          })
          .catch(() => {
          });
    },
    listarTotaisMetasDesconto() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/meta/desconto/real/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.metasDesconto = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisMetasPerDesconto() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/meta/desconto/perc/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.metasPerDesconto = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisMetasMes() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/meta/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.metasMes = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisMetasDia() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/meta/dia/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.metasDia = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisMetasVariacao() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/meta/variacao/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.metasVariacao = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisVariacoes() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/variacoes/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.variacoes = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisDevolucao() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/devolucao/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.devolucao = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisPerDevolucao() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/total/devolucao/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.perDevolucao = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisDesconto() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/desconto/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.desconto = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisMargem() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/margem/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.margem = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisMargemMeta() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/margem/meta/geral/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.margemMeta = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisDiasEstoqueFuturo() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/dias/estoque/futuro/geral/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.diasEstoqueFuturo = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisDiasEstoqueReal() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/dias/estoque/real/geral/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.diasEstoqueReal = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisEstoque() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/estoque/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.estoque = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisPedidos() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/pedidos/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.pedidos = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisItensAtivos() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/itens/ativos/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.itensAtivos = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisEstoqueObsoletos() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/itens/obsoletos/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.estoqueObsoletos = res.data.principal;
          })
          .catch(() => {
          });
    },
    listarTotaisItensEncalhados() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/itens/encalhados/geral/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.itensEncalhados = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisCurva() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/ruptura/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.curva = res.data;
            this.carregandoTotais2= false;
          })
          .catch(() => {
            this.carregandoTotais2= false;
          });
    },
    listarTotaisGeralCurva() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/total/curva/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.curvaGeral = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisRupturaA() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/rupturaa/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.rupturaA = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisRupturaBC() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/rupturabc/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.rupturaBC = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisGeralRuptura() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/total/ruptura/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.rupturaGeral = res.data;
          })
          .catch(() => {
          });
    },
    listarDataAtualizacao() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/dtinsercao/listar`)
          .then((res) => {
              this.dataUltAtualizacao = res.data;
          });
    },
    listarTotaisEstoqueAnoAnt() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/estoque/lastyear/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.estoqueAnoAnt = res.data;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEntradasSaidasAvulsa() {
      return axios
          .post(`${this.backendUrl}dashboard/entradasaida/avulsa/lista`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadores.entradasSaidasAvulsa = res.data.principal;
          })
          .catch(() => {
          });
    },


    // Totais secao
    listarTotaisVendasEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/total/venda/empresa/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.vendas = res.data.TotalSecao;
          })
          .catch(() => {
          });
    },
    listarTotaisObsoletosEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/obsoleto/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.vendasObsoletos = res.data.secoes;
          })
          .catch(() => {
          });
    },
    listarTotaisVendasLiquidaEmpresa() {
      this.busca.idsecao = null;
      return axios
          .post(`${this.backendUrl}dashboard/categoria/total/venda/liquida/empresa/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.variacoes.vendaLiquida = res.data.TotalVendaLiquida;
          })
          .catch(() => {
          });
    },
    listarTotaisParticVendasEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/total/venda/empresa/partic/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.perparticSecao = res.data.TotalParticSecao;
          })
          .catch(() => {
          });
    },
    listarTotaisParticRegiaoVendasEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/total/venda/empresa/partic/regional/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.perparticRegiao = res.data.TotalParticRegional;
          })
          .catch(() => {
          });
    },
    listarTotaisVendasEmpresaLastyear() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/total/venda/empresa/lastyear/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.variacoes.vendaAnoAnt = res.data.VendaLastYear;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisVendasEmpresaNominal() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/total/venda/variacao/nominal/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.variacoes.variacaoNominal = res.data.VariacaoNominal;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisVendasEmpresaLastmonth() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/venda/empresa/lastmonth/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.variacoes.vendaMesAnt = res.data.VendaLastMonth;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisVendasEmpresaVariacao() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/total/venda/variacao/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.variacoes.variacao = res.data.Variacao;
            this.carregando = false;
            this.carregandoTotais=false;
          })
          .catch(() => {
            this.carregando = false;
            this.carregandoTotais=false;
          });
    },
    listarTotaisEmpresaDevolucao() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/venda/devolucao/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.devolucao = res.data.Devolucao;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaPerDevolucao() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/total/devolucao/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.perDevolucao = res.data.Devolucao;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaDesconto() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/venda/desconto/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.descontos.desconto = res.data.Desconto;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaDescontoConcedido() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/venda/desconto/concedido/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.descontos.perdesconto = res.data.Desconto;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaMargem() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/venda/margem/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.margem = res.data.Margem;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaMargemMeta() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/margem/meta/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.margemMeta = res.data;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaMargemLastyear() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/venda/margem/lastyear/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.margemAnoAnt = res.data.MargemLastYear;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaDiasEstoqueFuturo() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/dias/estoque/futuro/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.diasEstoqueFuturo = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisEmpresaDiasEstoqueReal() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/dias/estoque/real/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.diasEstoqueReal = res.data;
          })
          .catch(() => {
          });
    },
    listarTotaisEmpresaEstoqueAnoAnt() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/estoque/lastyear/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.estoqueAnoAnt = res.data.EstoqueLastYear;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaEntradasSaidasAvulsa() {
      return axios
          .post(`${this.backendUrl}dashboard/entradasaida/avulsa/lista`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
          })
          .then((res) => {
            this.totalizadoresSecao.entradasSaidasAvulsa = res.data.secoes;
          })
          .catch(() => {
          });
    },

    listarTotaisEmpresaEstoqueInicial() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/estoque/inicial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.estoqueInicial = res.data.EstoqueInical;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaMovimentacoes() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/movimentacoes/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.movimentacoes = res.data.Movimentacoes;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEmpresaEstoqueFinal() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/estoque/final/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.estoqueFinal = res.data.EstoqueFinal;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisPedidosEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/pedidos/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.pedidos = res.data.Pedido;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisItensAtivosEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/itens/ativos/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.itensAtivos = res.data;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisEstoqueObsoletosEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/itens/obsoletos/listar`, {
            periodo: this.busca.periodo.data,
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.estoqueObsoletos = res.data.secoes;
          })
          .catch(() => {
          });
    },
    listarTotaisItensEncalhadosEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/itens/encalhados/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.itensEncalhados = res.data;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisCurvaAEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/curvaa/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.curvaA = res.data.CurvaA;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisCurvaBCEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/curvabc/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.curvaBC = res.data.CurvaBc;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisPerCurvaAEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/total/perccurvaa/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.perCurvaA = res.data.PercCurvaA;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisPerCurvaBCEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/perccurvabc/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.perCurvaBC = res.data.PercCurvabc;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisRupturaAEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/rupturaa/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.rupturaA = res.data.RupturaAFilial;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisRupturaBCEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/rupturabc/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.rupturaBC = res.data.TotalRupturaBcFilial;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisCurvaGeralEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/total/curva/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.curvaGeral = res.data.TotalCurvaFilialLoja;
            this.carregando = false;

          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarTotaisRupturaGeralEmpresa() {
      return axios
          .post(`${this.backendUrl}dashboard/categoria/onepage/total/ruptura/filial/listar`, {
            periodo: this.busca.periodo.data,
            idempresa: this.busca.idempresa,
          })
          .then((res) => {
            this.totalizadoresSecao.rupturaGeral = res.data.TotalRupturaFilialLoja;
            this.carregando = false;
            this.carregandoTotais2= false;
          })
          .catch(() => {
            this.carregando = false;
            this.carregandoTotais2= false;
          });
    },

		async init() {
			await this.listarPeriodos();
			await this.listarSecoes();
			await this.listarRegioes();
			await this.listarVendas();
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.border-line-head th {
  height: 35px !important;
}

.border-line td {
  border-right: 1px solid #CCCCCC76;
  height: 28px !important;
}

.border-line-foot td {
  border-right: 0px solid #023449;
  height: 28px !important;
}

.fixed-column {
  position: sticky  !important;
  left: 0  !important;
  z-index: 2  !important;
  background-color: #ECEFF1;
  border-right: 1px solid #023449 !important;
}

.fixed-column-header {
  position: sticky  !important;
  left: 0 !important;
  z-index: 3  !important;
  //background-color: #4C92B0  !important;
  //background-color: #395b84 !important;
  //background-color: #01579B !important;
  background-color: #1D5092 !important;
  border-right: 1px solid #023449 !important;
}

thead th {
  //background-color: #1D607D  !important;
  //background-color: #20456c !important;
  //background-color: #0277BD !important;
  background-color: #1D5092 !important;
}

tfoot td {
  //background-color: #B3E5FC;
  //background-color: #b7d5f1;
  //background-color: #E1F5FE;
  background-color: #D5D5D5 !important;
  border-left: 1px solid #B0BEC5;
}

.column-totais{
  background-color: #F2CB05;
}

.barra-total-left {
  //border-left: 1px solid #023449;
  //font-weight: bolder;
}

.barra-total-top td{
  height: 2px !important;
  background: #023449 !important;
}

.fixed-button{
  position: sticky  !important;
  left: 0  !important;
  z-index: 1  !important;
  background: #023449 !important;
}


.botao_detalhe {
  text-decoration: underline;
}

.botao_detalhe:hover {
  font-weight: bolder;
  text-decoration: none;
}

.header-pedido th {
  background-color: #888888 !important;
}

.botao_detalhe2 {
  text-decoration: underline;
}

.botao_detalhe2:hover {
  font-weight: bolder;
  text-decoration: none;
  padding-left: 18px !important;
}


.espaco-dinheiro {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  margin: 0px 0px 10px 0px;
}

.table_clicavel::v-deep .v-data-table__wrapper tr:hover {
  cursor: pointer;
}

::v-deep .cursor-normal {
  cursor: default !important; /* ou 'auto' */
}

.header th {
  background-color: #CFD8DC !important;
  height: 25px !important;
}

.body td {
  height: 20px !important;
  font-size: larger;
}

.foot td {
  height: 20px !important;
  font-weight: 500 !important;
  //background-color: #CFD8DC !important;
}

</style>